$chbx-size: 18px;
$tt: .2s;
$tt-toggle: .4s;

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

@mixin translateX ($x) {
  @include transform(translateX($x));
}

// ===========================
// toggle
// ===========================
.chkbx-toggle {
  display: none;

+ label {
  position: relative;
  cursor: pointer;
  outline: none;
  // @include user-select(none);
  width: 40px;
  height: $chbx-size + 2;
  background-color: darken($warm-grey, 5%);
  @include border-radius(60px);
  @include transition(background $tt-toggle);
  
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
  }
  
  &::before {
    right: 1px;
    left: -1px;
    top: 1px;
    bottom: 1px;
    background-color: $warm-grey;
    @include border-radius(60px);
    @include transition(background $tt-toggle);
  }
  
  &::after {
    top: 1px;
    left: 0;
    width: $chbx-size;
    height: $chbx-size;
    background-color: #fff;
    @include border-radius(100%);
    @include box-shadow(0 1px 5px rgba(0, 0, 0, 0.3));
    @include transition(transform $tt-toggle);
  }
}

&:checked + label {
    background-color: darken($grass, 5%);
  
  &::before {
    background-color: $grass;
  }
}

&:checked + label::after{
  @include translateX(20px);
}

&:disabled + label {
  opacity: 0.5;
  cursor: default;
}
}

.toggle-out {
  top: 5px;
}