.privacy-select {
    width: 200px !important;

}

.privacy-table {
  display: block;
  cursor: pointer;
  width: 100%;

  .scrollable-tbody {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
  }

  .domain-list {width: fit-content;}
    table {
      table-layout: fixed;
      width: 100%;
    }
    thead {
      th {
        border: none;

        // entity
        &:first-child {
          width: 17%;
        }

        // date
        &:last-child {
          width: 16%;
          text-align: center;
        }

        // domain
        &:nth-child(2){
          width: 17%;
        }
        //  provider count
        &:nth-child(5){
          width: 12%;
        }
        // source
        &:nth-child(4){
          width: 9%;
          text-align: center;
        }
        // risk
        &:nth-child(3){
          width: 14%;
        }
        &:nth-child(6){
          width: 15%;
          text-align: center;
        }
        //  registration
        // 18 + 20 + 10 + 10 == 58 100 - 58 = 42 26 - 26
        &.sortable {
          cursor: pointer;
        }
      }
    }
    tbody {
      font-size: 14px;
      color: $primary;
      width: 100%!important;
      tr {
        width: 100%!important;
        display: inline-block;
        .source-col {
          text-transform: capitalize;
          text-align: center;

        }
        td {
          border: none;
          &:nth-child(4){
            width: 9%;
          }
          &:nth-child(6){
            width: 15%;
            text-align: center;
          }
          &:nth-child(3){
            width: 14%;
          }
          &:nth-child(5){
            width: 12%;
          }
          &:nth-child(2){
            width: 17%;
          }
          &:first-child {
            width: 17%;
            opacity: 1;
          }
          &:last-child {
            width: 16%;
            text-align: center;
          }
        }
        &:nth-child(odd) {
          background-color: $pale-grey;
        }
      }
    }

    .log-display-row {
      display: table-row;
    }
  }

  .td-tcf {
    color: $secondary;
  }

.entity-detail-info {
  &.bf {
    padding-top: 27px;

    >i {
      color: #797979;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;

      >a {
        color: #208e9b;
        font-weight: normal;
      }
    }
  }

  span {
    text-align: left !important;
  }

  ul {
    width: 30%;
    background-color: #f4f4f4;
    list-style: none;
    margin-top: 7px;
    margin-bottom: 9px;
    padding-left: 0 !important;

    li {
      padding: 0.75rem;
    }
  }

  .main {
    font-weight: bold;
    color: $primary;
  }

  .info {
    font-style: italic;
    color: $subheader-gray;
  }

  .tcf-cookie-title {
    padding-left: 0;
    text-transform: uppercase;
    font-size: 12px;
    color: $steel;
  }

  .tcf-cookie-info {
    text-transform: capitalize;
    color: $confiant-purple;
    font-weight: 600;
  }
}

.privacy-top-row {
  display: table-row;

  td{
    span{
      color: $subheader-gray;
    }
  }
}

.table-container {
  position: relative;
  max-height: 500px;
  overflow-y: auto;
}

.download-icon {
  &--privacy{
    text-align: right;
    font-size: 1rem;

    i {
      color: $primary;
      cursor: pointer;
    }
  }
  i {
    color: $primary;
  }
}


.box-div {
  -webkit-flex: 1;
  -moz-flex: 1;
  flex: 1;
  white-space: nowrap;
  display: flex;
  flex-direction: row;

  &:last-child {
    margin-right: 0 !important;
  }
}

.tracking-box-container {
  cursor: pointer;
  height: 188px;
  width: 30%;
  border: 1px solid #b3b3b3;
  box-sizing: border-box;

  &.active {
    border-color: #f2f2f2;
  }
  
  .tracking-box {
    height: 100%;
    &.active {
      background-color: #f2f2f2;
      border: 1px solid #f2f2f2;
    }

    .box-1  {
      margin-top: 1px;
      font-size: 50px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      color: $chart-blue;
      height: 40%;

      >img {
        width: 60px;
        height: 50px;
        object-fit: contain;
      }
    }

    .box-2 {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #727272;
      height: 35%;

      .counts {
        font-size: 0.85rem;
        font-weight: bold;
        span {&:nth-child(odd) {
          color: $chart-blue;
        }}
      }
      
    }

    .btn-box { 
      height: 25%;

      // margin-top: 24px;
      // margin-bottom: 24px;
      
      .btn-secondary.active {
        background-color: $secondary !important;
        border-color: $secondary !important;
      }

      .details {
      font-size: 16px !important;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal !important;
      letter-spacing: normal;
      text-align: center;
    }}
  }

 
}

.triangle {
  position: relative;
  margin-top: 5px;
  
  .down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid $secondary;
    margin: auto;
  }
}

.tracking-desc {
  padding-top: 30px;
  font-size: 14px;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
}

.no-tracking-details {
  padding-top: 63px;

  >i {
    color: $success-green;
  }

  >span {
    font-size: 16px;
    font-weight: 600;
    color: $primary;
  }
}

.tracking-details-table {
  padding-top: 13px;
  width: 100%;

  table{
    width:100%;
    table-layout:fixed;
  }
  tbody{
    width:100% !important;
    max-height: 232px;
    display: block;
    overflow-y:auto;
    border: 2px solid #bebebe;
  }
  thead{
    min-width:100% !important;
  }
  thead tr{
    width: 100% !important;
    display:inline-block;
    
    th{
      color: $primary;
      font-size: 14px;
    }
  }
  tbody tr{
    width:100% !important;
    display:inline-block;

    td {
      &:first-child {
        opacity: 1;
        color: $primary; 
        font-weight: 600;
      }

      &:nth-child(2) {
        color: #000000;
      }
      
      &:last-child {
        color: #000000;
      }
    }

    &:nth-child(odd) {
        background-color: #f4f4f4;
      }
  }

  th, td{
    display:inline-block;
    text-align:left;

    &:first-child {
      width:35% !important;
      &.localStorage {
        width:50% !important;
      }
      min-width: 120px;
    }
    &:nth-child(2) {
      width:25% !important;
      min-width: 120px;
      &.localStorage {
        width:25% !important;
      }
    }
    &:nth-child(3) {
      width:15% !important;
      min-width: 120px;
      // padding-right: 30px;
    }
    &:last-child {
      // width:25% !important;
      min-width: 120px;     
    }
  }

  td {
    padding: 0.75rem;
  }

  th {
    padding-left: 0.75rem;
    padding-bottom: 0.25rem;

  }
}

.truncate-text-entity {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  cursor: default;



  &.font-larger {
    font-size: 1.25rem;
  }
}

.table-entityreport-responsive {
  border: 1px solid #e8e8e8;
  overflow-y: auto;
  table-layout: auto;

  th {
    font-weight: bold;
    color: $primary;
    line-height: normal;
    letter-spacing: normal;
    font-size: 1em;
  }
  td {
    font-size: 1em;
    a {
      font-weight: normal;
      color: $chart-teal;
    }
  }
}

.entity-providers-table {
    height: 230px;
    overflow-y: scroll;
    overflow: hidden;
}




/* The scrollable part */

.scrollable {
  max-height: 135px;
  font-size: 85%;
  overflow-y: scroll;
  border: 1px solid darken($white-three, 10%);

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(odd) {
    background-color: $white-three;
  }

  th {
    position: sticky;
    background-color: white;
    z-index: 2;
    top: 0;
  }

  a {
    font-weight: normal;
    color: $chart-teal;
  }

  span {
    color: $warm-grey;
  }
}

.providers-sticky-header {
  th {
    text-align: left;
    padding: 8px;
    color: $subheader-gray;
    font-weight: 600;
  }
}

.risk-level {
  text-transform: capitalize;
  
}

.risk-header {
  text-transform: uppercase;
  font-size: larger;
  font-weight: bold;

  &.high {
    color: $chart-red;
  }
  
  &.medium {
    color: $warning-yellow;
  }
  
  &.low {
    color: $success-green;
  }

  &.pending {
    color: $steel;
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;

  &.medium {
    background-color: $warning-yellow;
  }

  &.low {
    background-color: $success-green;
  }

  &.high {
    background-color: $chart-red;
  }

  &.pending{
    color: #bbb;
  }
}

.entity-dot {
  height: 9px;
  width: 9px;
  background-color: #ADADAD;
  border-radius: 50%;
  display: inline-block;

  &.medium {
    background-color: $warning-yellow;
  }

  &.low {
    background-color: $success-green;
  }

  &.high {
    background-color: $chart-red;
  }
}


.risk-assessments {
  
  .info {
    font-style: italic;
    color: $subheader-gray;
  }

  table {
    tbody {
      tr {
        border-top: none !important;
      }
      tr:not(:last-child){
        border-bottom: none !important;
      }
      tr {
        td {
          span {
            text-align: left;
            word-break: normal;
          }
          span:first-child {
            flex: 0 0 80px;
          }
        }
      td:last-child {
        width: 98%;
        padding-left: 12px !important;
      }}
    }
    
  }
  
  
}

.high-color {
  color: $chart-red;
}

.pending-color {
  color: $steel;
}

.popover-modal {
  z-index: 99999999999999 !important;
    max-width: 400px !important;
    position: absolute;
    top: 50px;
    left: 0px;
    transform: translate3d(870px, 354px, 0px);
    will-change: transform;

    .arrow {
      top: 75px;
    }
}

.multi-expandable-list {
  a {
    color: $subheader-gray;
    text-transform: uppercase;
  }

  .long-list {
    max-height: 270px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}