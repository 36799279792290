$confiant-purple:     #150d45;
$warm-grey:       #999999;
$confiant-red:     #ef1e4a;
$dark-slate-blue: #211656;
$darkest-slate-blue:  #1a173b;
$darkish-purple:  #20072e;
$black:           #000;
$pale-grey:       #fafbfc;
$white:           #fff;
$steel:           #788599;
$dusky-blue:      #48688c;
$white-two:       #e8e8e8;
$white-three:     #eeeeee;
$grass:           #31a228;
$teal:            #20c997;

$toggle-active:   #150d45;
$toggle-inactive: #e8e8e8;

$chart-red: #CF2D4F;
$chart-purple: #322773;
$chart-blue: #0E6996;
$chart-teal : #208E9B;
$chart-sage: #8CBFAF;
$privacy-gold: #E3A342;
$warning-yellow: #F2C94E;
$success-green: #3FC4AE;
$sidebar-purple: #7770A1;
$quality-icon-purple: #5341BA;
$subheader-gray: #788599;
$border-light: #dee2e6;
$inactive-tab: #c3cdd8;
$table-bg-blue: #F4F8FA;


$primary:       $confiant-purple !default;
$secondary:     $confiant-red !default;
$success:       $grass !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

// Typography
// -------------------------
$body-font: 'Open Sans', sans-serif !default;

$body-bg: $white;
$heading-text-color: $black;
$text-color: $black;
$link-color: $darkish-purple;