/*
 * Sidebar
 */
 .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1031; /* Behind the navbar */
  padding: 0; /* Height of navbar */

  

  
  
  .navbar-brand {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  .nav-link {
    font-weight: 400;
    color: $white;
    vertical-align: middle;
    text-transform: uppercase;
    opacity: 0.6;

    &.active {
      color: $primary !important;
      background-color: $white;
			opacity: 1;
			font-weight: bold;
			
    }

    &:hover, &:active {
      color: $white !important;
			opacity: 1;
			background-color: $sidebar-purple;
			font-weight: normal;
    }

    .sidebar-icon {
      margin-right: 10px;
      min-width: 25px;
      color: $white;
      font-size: 1.4em;
			vertical-align: middle;
			
			&::after {
				color: $primary;
				background-color: $white;
				opacity: 1;
			}
    }
	}
	
	.icon-settings:active {
			color: $primary;
			opacity: 1;
	}

  	.secondary-menu {
		padding-top: 10px;
		padding-left: 17px;
		padding-right: 17px; 

		&.menu-top-shift{
			margin-top:30px;
		}

		.login-btn {
			background-color: $secondary;
			text-align: center;
			display: block;
			color: #fff;
			padding: 9px;
			text-transform: uppercase;
			margin-bottom: 40px; 
			&:hover {
				text-decoration: none; 
			}
		}
		
		.social-links-container{
			margin-bottom:30px;
			margin-left: 5px;
			margin-top: 60px;
			
			p {
				opacity: 0.6;
			}
			
			a {
				background-color: $secondary;
				color: $primary;
				border-radius: 96px;
				font-size: 19px;
				margin-left: 10px;
				width: 35px;
				height: 35px;
				display: inline-block;
				text-align: center;
				padding-top: 4px;
				img{
				    position: relative;
					top: -2px;
					height: 32px
				}
			}
		}
		
		.copy-container {
			font-weight: 400;
			color: #fff;
			vertical-align: middle;
			line-height: 30px; 

			p {
				opacity: 0.6; 
			}
			a {
				display: inline-block;
				font-weight: 400;
				color: #fff;
				margin-right: 10px;
				opacity: 0.6; 
				
				&:active, &:hover {
					text-decoration: none;
					opacity: 1 !important; 
				}
			}
		}
	}

  
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

.middle-test {
	width: 50%; margin: 0 auto;
}

.Row {
	display: table;

	/* optional */
	// border-spacing: 5pt;
}

.Col {
	display: table-cell;

	/* optional */
	width: 150pt;
}

.TopLeft {
	text-align: left;
		align-items: left;
	vertical-align: top;
}
.TopCenter {				
	text-align: center;
		align-items: center;
	vertical-align: top;
	font-size: 13px;
}
.TopRight {
	text-align: right;
		align-items: right;  
	vertical-align: top;
}

.MiddleLeft {
	text-align: left;
	align-items: left;
	vertical-align: middle; 
}
.MiddleCenter {
	text-align: center;
	align-items: center;
	vertical-align: middle;
}
.MiddleRight {
	text-align: right;
	align-items: right;
	vertical-align: middle;
}

.BottomLeft {
	text-align: left;
	align-items: left;
	vertical-align: bottom;
}
.BottomCenter {
	text-align: center;
	align-items: center;
	vertical-align: bottom;
}
.BottomRight {
	text-align: right;
	align-items: right;
	vertical-align: bottom;
}