html, body {
  height: 100%;
  min-width: 540px;
}

body {
  font-family: $body-font;
  color: $text-color;
  background-color: $body-bg;
  padding-top: 50px;
  font-size: 0.875em;
}

article,
section {
  position: relative
}

.body-wrapper {
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin icomoon-font {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $heading-text-color;

  small,
  .small {
    font-weight: normal;
    line-height: normal;
    font-size: 80%;
  }
}

.upper-case {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.disp-none {
  display: none !important;
}

a {
  @include transition (all ease-in-out 0.3s);
  color: $link-color;
}

.primary-bg-color {
  background: $primary;
  color: $white;
}

/*
 * Content
 */

[role="main"] {
  // padding-top: 48px; /* Space for fixed navbar */
}

.page-header-out {
  background: $white;

  &.complex-header {
    padding: 0;
  }

  h1 {
    display: inline-block;
    font-size: 3em;
    color: #1a173b;
    line-height: 1;

    &.header-trim {
      max-width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: visible;
      text-overflow: ellipsis;
      padding-bottom: 0.3em;
      margin-bottom: -0.3em;
      margin-top: -4px;
    }


    .small {
      font-family: 'Inconsolata', monospace;
      font-size: 0.32653em;
      margin-left: 30px;
      color: $warm-grey;
    }

    .sub-heading {
      font-size: .5em;
      color: $steel;
    }
  }

  .page-header-out-right-icons {
    .btn {
      font-size: 1.4em;
      line-height: 1.5em;
      padding-top: 1rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      &.btn-light {
        background-color: transparent;
        border: none;

        &:hover {
          color: lighten($darkest-slate-blue, 10%);
        }
      }

      &.top-shift-3 {
        position: relative;
        top: 3px;
      }

      .new-notification {
        &::after {
          width: 10px;
          height: 10px;
          @include border-radius(10px);
          background: $confiant-red;
          position: absolute;
          content: '';
          left: 16px;
          top: 18px;
        }
      }
    }
  }
}

h2 {
  &.page-sub-title {
    font-size: 1.143em;
    text-transform: uppercase;
    margin: 0 0 0 30px;
    font-weight: 600;
    display: inline-block;

    a.icon-info,
    .icon-info {
      vertical-align: middle;
      color: $steel;
      font-size: 1.1em;
      line-height: 1.2em;
      margin-left: 10px;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.company-logo {
  background: url('../public/static/images/logo.svg') left top no-repeat;
  display: inline-block;
  min-width: 100px;
  height: 34px;
}

.sm-header-out {
  &.navbar-brand {
    padding: 0.5em;
  }

  a {
    &.company-logo {
      background: url('../public/static/images/logo.svg') center center no-repeat;
      display: inline-block;
      width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }


  .hamburger-btn,
  .close-hamburger-btn,
  .close-notification-btn {
    &.btn-light {
      background-color: transparent;
      border-color: transparent;
      color: $white;
      font-size: 1.4em;
      padding: 0.2em 10px 0 10px;

      &:hover, &:active, &:focus, &:visited {
        background: transparent;
        border: transparent;
        opacity: 0.8;
      }

      &:focus {
        padding: 0.2em 10px 0 10px;
      }
    }
  }

  .close-hamburger-btn,
  .close-notification-btn {
    &.btn-light {
      font-size: 1em;
      padding: 0.6em 10px 0 10px;

      &:hover, &:active, &:focus, &:visited {
        background: transparent;
        border: transparent;
        opacity: 0.8;
      }

      &:focus {
        padding: 0.6em 10px 0 10px;
      }
    }
  }

  .page-header-out-right-icons {
    .btn-light,
    .close-notification-btn {
      background-color: transparent;
      border-color: transparent;
      color: $white;
      font-size: 1.4em;
      padding: 0.2em 10px 0 10px;

      &:hover, &:active, &:focus, &:visited {
        background: transparent;
        border: notransparentne;
        opacity: 0.8;
      }
    }

    .close-notification-btn {
      font-size: 1em;
      padding-top: 0.5em;
    }

    .new-notification {
      &::after {
        width: 10px;
        height: 10px;
        @include border-radius(10px);
        background: $confiant-red;
        position: absolute;
        content: '';
        left: 22px;
        top: 6px;
      }
    }
  }
}

.common-container {
  padding-left: 30px;
  padding-top: 15px;
  font-size: 0.875em;

  &.no-lr-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &.no-t-padding {
    padding-top: 0;
  }

  &.login-page {
    border-top: 1px solid #dee2e6;
    padding-top: 2rem;
    margin-top: 1rem;
  }

  .reset-pwd-success-container {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;

    h1 {
      margin-top: 60px;
      margin-bottom: 75px;
      line-height: 1.4;
    }
  }

  .row-form-mb {
    margin-bottom: 30px;
  }

  .row-form-mb-10 {
    margin-bottom: 10px;
  }

  form {
    label {
      color: $primary;
      font-weight: 600;
      font-size: 0.75rem;

      &.sml {
        font-size: 0.875em;
      }

      &.no-wt {
        font-weight: 500;
      }

      &.lbl-top-shift {
        margin-top: 5px;
      }

      &.lbl-left-shift {
        margin-left: 20px;
      }
    }
  }

  .filter_heading {
    color: $steel;
    font-size: .75em;
    margin-bottom: 4px;
  }
}

label.col-form-label {
  color: $toggle-active;
  font-weight: 600;
  //font-size: 0.875em;
  &.no-wt {
    font-weight: 500;
  }
}

.col-form-lbl-pr {
  padding-right: calc(0.375rem + 2px)
}

.form-control {
  background-color: $white-three;
  border-color: $white-three;
  @include border-radius(3px);
  font-size: 1em;

  &:disabled {
    background-color: lighten($white-three, 1%);
    border-color: lighten($white-three, 1%);
    color: lighten($text-color, 60%);
  }

  &:focus {
    background-color: $white-three;
    border-color: $white-three;
  }

  &.has-errors {
    border-color: #dc3545;
    box-shadow: 0 0 0 $input-focus-width rgba(#dc3545, .25);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
    background-size: calc(.75em + .375rem) calc(.75em + 0.375rem);
    background-repeat: no-repeat;
    background-position: 100% calc(0.375em + 0.1875rem);
  }
}

a {
  color: $darkish-purple;
  font-weight: 700;
  text-decoration: none;

  &:hover, &:active, &:visited, &:focus {
    text-decoration: underline;
    outline: 0px;
  }
}

.no-linked-alert {
  font-size: 1.7em;
  color: $secondary;
  vertical-align: middle;
  margin-left: 5px;
}

.dropdown {
  &.dropdown-big {
    background: $white;
    display: inline-block;
    font-size: 1em;
    position: relative;

    a {
      vertical-align: middle;
      color: $steel;
      font-size: 1.45em;
      line-height: 1.2em;
      margin-left: 10px;
      text-decoration: none;

      &.dropdown-toggle {
        text-decoration: none;

        &::after {
          display: none;
        }
      }
    }

    h2 {
      &.blocking-info-title {
        text-transform: uppercase;
        font-size: 1em;
        font-weight: 600;
        line-height: 1.38;
        margin-bottom: 20px;
      }
    }

    .blocking-info-row {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        margin: 0 0 5px 0;
        font-size: 0.875em;
        color: $dark-slate-blue;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: 0.9px;
      }

      p {
        margin: 0;
        padding: 0;
        color: $steel;
        font-size: 0.9em;
        font-size: 0.875em;
        line-height: 1.29;
      }
    }

    .dropdown-menu {
      width: 400px;
      padding: 24px;
      font-size: 1em;
      @include border-radius(2px);
      box-shadow: 0 0 1.2rem rgba(0, 0, 0, 0.18);
      border: none;
      left: -190px !important;

      &::before {
        border-color: transparent;
        border-style: solid;
        border-width: 8px;
        border-bottom-color: $white;
        position: absolute;
        content: '';
        left: calc(50% - 10px);
        top: -14px;
      }

    }
  }
}

.color-dark-indigo {
  color: $confiant-purple;
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.3s;
}


.toggle-out {
  display: none;

  label {
    margin-bottom: 0;
  }
}

.radio-on-off-out {
  display: inline-flex;
  margin-right: 1.56rem;

  &.shift-right {
    margin-right: 3rem;
  }
}

.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/
  /*float: left;*/

  &:after {
    content: '\e903';
    font-family: 'icomoon' !important;
    color: $steel;
    right: 11px;
    top: 6px;
    height: 34px;
    padding: 10px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
  }


  &.no-border {
    display: inline-block;
    width: 172px;
    position: relative;

    select, input {
      border: 0;
      text-decoration: underline;
      font-size: 18px;
      color: #788599;
    }

    &:after {
      color: #000;
      font-size: 13px;
    }
  }

  &.top-shift-16 {
    top: -16px;
  }
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  //display: none;
}

.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */

  display: block;
  width: 100%;
  max-width: 320px;
  height: 40px;
  float: right;
  margin: 5px 0px;
  padding: 0px 28px 0px 12px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #979797;
  -ms-word-break: normal;
  word-break: normal;

  &::-ms-expand {
    display: none;
  }
}


.inputdiv {
  margin-top: 22px;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add some styling */

    display: block;
    width: 100%;
    max-width: 320px;
    height: 40px;
    margin: 5px 0px;
    padding: 0px 24px 0px 12px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #979797;
    -ms-word-break: normal;
    word-break: normal;

    &::-ms-expand {
      display: none;
    }
  }
}

.react-bootstrap-daterangepicker-container {
  padding-left: 15px;
  display: block !important;

  &:hover {
    background: #f8f9fa;
  }
}

#reportrange {
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  white-space: nowrap;

  .fa-calendar {
    font-size: 17px;
    margin-right: 7px;
    position: relative;
    top: 1px;
  }
}

hr {
  background-color: $steel;
}

.grey-top-border {
  border-top: 1px solid $steel;
  
  &--second {
    border-top: 2px solid $steel;
  }
}


.table-responsive-lg {
  overflow: auto;
}

.table-responsive {
  display: block;
  max-height: 445px;
  &.reports {
    max-height: calc(100vh - 400px) !important;
  }
  
  overflow: auto;

  @media (max-width: 1366px) {
    max-width: 1068px;
    table.table-borderless tbody {
      font-size: 1.135em;

      .small-font {
        font-size: .7em !important;
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 783px;
  }

  @media (max-width: 767px) {
    max-width: 704px;
  }

  table td.td-padding4 {
    padding: 4px !important;
  }

}

.alertLogMainContainer {
  table {
    table-layout: fixed;
  }
}

table {

  &.table-borderless {
    @media (max-width: 1200px) {
      width: auto !important;
    }

    tbody.collapse {
      tr.clickable {
        background: #fafbfc
      }
    }

    thead, tbody {
      tr {
        th, td {
          &:nth-child(1) {
            width: 25px;
            text-align: right;

            .icon-right-arrow {
              position: relative;
              right: -14px;
              top: 1px;
            }
          }
        }

        th {
          position: relative;

          .headerArrow {
            position: absolute;
            right: 5px;
            font-size: 25%;

            span {
              display: block;

              .bold {
                font-weight: bold !important;
                color: #711c1c !important;
              }
            }
          }
        }

        th, td {
          &:nth-child(2) {
            width: 150px;
          }

          &:nth-child(3),
          &:nth-child(4) {
            width: 120px;
          }

          &:nth-child(5) {
            width: 75px;
          }

          &:nth-child(6) {
            width: 95px;
          }

          &:nth-child(7),
          &:nth-child(8) {
            width: 100px;
          }

          &:nth-child(9) {
            width: 110px;
          }
        }
      }
    }
  }

  .dropdown-menu {
    right: 41px;
    left: auto;
    width: 400px;
    top: 70%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border: 0;

    > div {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .dropdown.show .icon-notification {
    color: rgba(21, 13, 69, 0.5);
  }


  border-spacing: 0px 20px;

  thead {
    th {
      color: $steel;
      vertical-align: top !important;

      .headerText {
        font-size: .8em;
        width: calc(100% - 10px);
        float: left;
      }
    }
  }

  tbody {
    tr {
      box-sizing: content-box;

      &.main {
        background-color: $white-three;
        border: solid 1px $white-two;
      }
    }

    tr[aria-expanded="true"] {
      .icon-right-arrow:before {
        content: "\e903" !important;
      }
    }

    svg {
      width: 70px !important;
      height: 40px !important;
    }
  }
}

#line-chart_container, table, .alert-log-table-body {
  path {
    stroke: black;
    stroke-width: 2;
    fill: none;
  }

  .axis path, .axis line {
    fill: none;
    stroke: grey;
    stroke-width: 1;
    shape-rendering: crispEdges;
  }
}

.no-left-margin {
  margin: 0px !important;
}

.width-120 {
  width: 120px !important;
}

.width-75 {
  width: 75px !important;
}

.min-width-75 {
  min-width: 75px !important;
}

.width-80 {
  width: 80px !important;
}

.width-100 {
  width: 100px !important;
}

.width-110 {
  width: 110px !important;
}

.width-300 {
  width: 300px !important;
}

.small-font {
  font-size: .75em !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.font-light {
  color: $steel !important;
}

.font-14 {
  font-size: 0.875em;
}

.dark-blue-font {
  color: $dark-slate-blue;
}

.yellow-background {
  background-color: #f9ed62;
}

#customize_view.visible-state {
  .icon-right-arrow:before {
    content: "\e903" !important;
  }
}

.clipboard-alert {
  width: 101% !important;
  margin-left: -19%;

  .icon-close {
    cursor: pointer;
    margin-top: 5px;
  }
}

/*overriding react block UI library*/
.block-ui-container {
  position: fixed !important;
  z-index: 1050 !important;

  .block-ui-overlay {
    opacity: 0.0;
    background-color: transparent;
  }
}

.block-ui-container .loading-bullet {
  font-size: 5em !important;
  color: red;
}

.row .block-ui-container {
  position: relative !important;
}

.modal-sticky {
  max-height: 400px;
  overflow-y: auto;
}

.font-large {
  font-size: 1.5rem;
}


/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.redux-toastr {
  .top-right {
    //position: absolute !important;
    position: fixed !important;
    //top: -100px !important;
    top: -3px !important;
    right: 6px !important;
    width: 80% !important;
    // height: 70px !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    max-width: 700px;
    border-radius: 16px;
    z-index: 999999999;

    .toastr-icon {
      margin-top: 0 !important;
    }

    .close-toastr {
      font-size: 1rem;
      color: gray;
      opacity: 1 !important;
      top: -24px;
      right: -15px !important;
      font-size: 0.8rem !important;
      font-weight: 600;
    }

    .toastr {
      .rrt-middle-container {
        width: 90%;
        font-size: 14px !important;
        color: $primary !important;
        padding-top: 10px;
        padding-bottom: 14px;
        margin-bottom: 4px;
        margin-left: 5%;
      }

      .rrt-left-container {
        width: 3% !important;
      }

      &.rrt-success {
        background: #fafafa !important;
        border-radius: 0 !important;
        box-shadow: 1px 4px #e0e0e0 !important;

        .rrt-left-container {
          background: $success-green !important;
        }
      }

      &.rrt-error {
        background: #fafafa !important;
        border-radius: 0 !important;
        box-shadow: 1px 4px #e0e0e0 !important;

        .rrt-left-container {
          background: $warning-yellow !important;
        }

        i.fa-ban {
          font-size: 35px;
          color: #ef1e1e;
          top: 4px;
          position: relative;
        }
      }

      &.rrt-info {
        background: #fafafa !important;
        border-radius: 0 !important;
        box-shadow: 1px 4px #e0e0e0 !important;

        .rrt-left-container {
          background: $chart-blue !important;
        }
      }

      &.rrt-warning {
        background: #fafafa !important;
        border-radius: 0 !important;
        box-shadow: 1px 4px #e0e0e0 !important;

        .rrt-left-container {
          background: $chart-sage !important;
        }
      }

    }

  }
}

.date-filter-actions-wrap {
  > div {
    display: inline-block;
    position: relative;

    &.date-filter-container {
      margin-right: 10px;

      .react-bootstrap-daterangepicker-container {
        padding-left: 0 !important;
      }

      .btn {
        background: transparent;
        padding-left: 0;
        font-size: .7rem;

        &:focus, &:active, &:hover {
          box-shadow: 0 0 0 red;
          border-color: transparent;
        }

        span {
          color: #788599;
        }

        .fa-calendar {
          margin-right: 5px;
          color: $black;
        }

        .icon-down-arrow {
          font-size: 12px;
          color: $black;
        }
      }
    }

    &.submit-filters-container,
    &.reset-filters-container {
      margin-right: 5px;
      white-space: nowrap;

      .reset-filters {
        position: absolute;
        bottom: -25px;
        left: 0px;
        cursor: pointer;
        font-weight: 700;
        color: #000;
      }

      button {
        font-size: .8rem;
      }
    }

    &.reset-filters-container {
      padding-left: 5px;

      a {
        font-weight: normal;
        text-decoration: underline;
      }
    }

    &.export_csv_container {

      button {
        font-size: .7rem !important;
      }
    }
  }
}


@media (max-width: 1000px) {
  .reset-filters-container {
    margin-bottom: 24px;
  }
}

@media (max-width: 900px) {

  .reset-filters-container {
    .reset-filters {
      position: absolute;
      bottom: -5px;
      left: 15px;
    }
  }

}

.dropdown-menu {

  &.my-account-dropdown {
    left: -18px !important;
    top: -12px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    font-size: 0.9em;
    margin: 0;

    > li {
      a {
        cursor: pointer;
        padding: 0 15px;
        display: block;
        line-height: 33px;
        color: #444444;
        text-decoration: none;
        border-bottom: 1px solid #dddddd !important;

        &:last-child {
          border-bottom: 0px solid #dddddd !important
        }

        &:hover {
          background: #eeeeee;
        }
      }
    }


  }
}

button.link-btn {
  border: 0;
  color: #4f1170;
  background: none;
  font-size: 12px;
  padding-right: 0;
  padding-left: 0;
  outline: 0;

  &:focus, &:active {
    outline: 0;
  }
}

.form-btm-bdr {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 40px;

  &.no-border {
    border-width: 0;
  }
}

hr.border-line {
  border: none;
  border-bottom: 1px solid #dddddd;
  margin: 0;
  margin-left: -30px;
}


.data-usage-container {
  text-align: center;
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 18px;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background, .circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: black;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 2em;
  fill: gray;
}

.disabled-link {
  color: #cccccc;

  &:hover, &:active {
    color: #cccccc;
  }
}

.daterangepicker {
  color: $toggle-active !important;

  td {
    &.in-range {
      color: $toggle-active !important;
      background-color: #F9DAE1 !important;
    }

    &.active {
      background-color: $secondary !important;
      color: white !important;

      &:hover {
        background-color: $secondary !important;
        color: white !important;
      }

      &.in-range {
        background-color: $secondary !important;
        color: white !important;

        &:hover {
          background-color: $secondary !important;
          color: white !important;
        }
      }
    }
  }

}

[class*='group-of-rows'] {
  border: 1px solid #e8e8e8 !important;
}


.positioned-to-top {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 20px;
    z-index: 1031;
    h1 {
      display: inline-block;
      font-size: 3em;
    }
  }
}

.btn-text {
  background: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.btn-collapse {
  display: block;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.143em;
  position: relative;

  &:after {
    @include icomoon-font;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    content: "\e90f";
  }

  &[aria-expanded="false"] {
    &:after {
      content: "\e90f";
    }
  }

  &[aria-expanded="true"] {
    &:after {
      content: "\e903";
    }
  }
}

.rel-top-shift-5 {
  position: relative;
  top: 5px;
}

.flex-ele-wrap > div {
  display: flex;
}

.table-responsive,
.alert-log-container {
  .no-record-found {
    position: fixed;
    left: 50%;
    margin-left: -50px;
    margin-top: 130px;
  }

  .table-loading-image {
    position: absolute;
    left: 50%;
    margin-left: -100px;
    top: 210px;
  }
}


// Used Currently for Alert Log but could be abstracted for other Data Tables
@mixin up-arrow {
  content: '\25B4';
}

@mixin down-arrow {
  @include up-arrow;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

@mixin left-arrow {
  @include up-arrow;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

@mixin right-arrow {
  @include up-arrow;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.data-table-wrapper {
  font-size: 85%;
  margin-top: 15px;

  table {
    width: 100%;

    thead {
      th {
        color: #788599 !important;
        font-size: 0.875em !important;
        text-align: center !important;
        position: relative;
        outline: none;
        vertical-align: middle !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(7), &:nth-child(9) {
          cursor: pointer;

          span {
            padding: 0 8px;
          }

          &:before {
            content: '\25B4';
            display: inline-block;
            position: absolute;
            height: 12px;
            right: 0;
            top: 50%;
            margin-top: -12px;
            font-size: 10px !important;
          }

          &:after {
            content: '\25B4';
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            display: inline-block;
            position: absolute;
            height: 12px;
            right: 0;
            top: 56%;
            margin-top: -3px;
            font-size: 10px !important;
          }

          &.sorting_asc:before {
            font-weight: bold;
            color: #711c1c;
          }

          &.sorting_desc:after {
            font-weight: bold;
            color: #711c1c;
          }
        }
      }
    }

    tbody {
      overflow-y: auto;
      overflow-x: hidden;

      .dtrg-level-0 {
        background: #eeeeee !important;
        border: solid 1px #e8e8e8 !important;
        display: table-row !important;


        td {
          text-align: right !important;
          font-weight: bolder !important;
          padding: 0.75rem 0 !important;

          &:nth-child(1) {
            text-align: left !important;
            position: relative;
            padding-left: 20px !important;

            &:before {
              @include down-arrow;
              position: absolute;
              left: 10px;
            }
          }
        }

        &.collapsed {
          td:nth-child(1):before {
            @include right-arrow;
          }
        }
      }

      .dtrg-level-1 {
        background: #fafbfc !important;
        border: solid 1px #e8e8e8 !important;

        td {
          text-align: left !important;
          padding-left: 1.8rem !important;

          &:nth-child(1) {
            position: relative;

            &:before {
              @include down-arrow;
              position: absolute;
              top: 30%;
              bottom: auto;
              left: 1.1rem;
              font-size: 125%;
              margin: auto 0;

            }
          }
        }

        &.collapsed {
          td:nth-child(1):before {
            @include right-arrow;
            left: 0.6rem;
            top: 0;
            bottom: 0;
          }
        }
      }

      tr {
        border: 1px solid #e8e8e8 !important;
        display: none;

        td {
          padding: 0.75rem 0 !important;

          &:nth-child(2) {
            :first-child {
              width: 100% !important;
              word-break: break-all;
            }
          }
        }

        &.row {
          background: #fff;

          &:hover {
            background: #fafbfc !important;
            cursor: pointer;
          }

          td {
            &:nth-child(8) {
              text-align: center !important;
            }
          }
        }
      }
    }

    .devices-icons {
      font-size: 15px;
      opacity: 0.7;

      i {
        padding-right: 3px;
      }
    }
  }
}

.table-fixed {
  width: 100% !important;
  overflow-y: hidden !important;
  margin-bottom: 0px !important;

  thead {
    display: block;

    tr {
      display: block;

      th {
        float: left;
        display: block;
        width: 10% !important;

        &:nth-child(1) {
          width: 1% !important;
        }

        &:nth-child(2) {
          width: 39% !important;
        }

        &:nth-child(7) {
          width: 10% !important;
        }

        i {
          margin-left: 5px;
        }
      }

      &:after {
        content: "";
        clear: both;
        display: table;
      }
    }
  }

  tbody {
    display: block;
    overflow-y: auto;
    width: 100%;

    tr {
      display: none !important;
      width: 100% !important;

      td {
        float: left;
        width: 10% !important;

        .blocking-status-icon {
          top: 1px !important;
          right: 3px !important;
          font-size: 125%;
        }

        .blocking-status-icon + .blocking-status-text {
          font-size: 95%;
          top: 0 !important;
        }
      }

      &:after {
        content: "";
        clear: both;
        display: table;
      }

      &.row {
        margin-right: 0 !important;
        margin-left: 0 !important;
        text-align: right !important;

        td {
          width: 10% !important;

          &:nth-child(1) {
            width: 2.6% !important;
          }

          &:nth-child(2) {
            width: 37.4% !important;
            text-align: left !important;
          }

          &:nth-child(6) {
            padding: 0 !important;
          }

          &:nth-child(7) {
            width: 10% !important;
          }

          .recharts-wrapper {
            margin: 0 0 0 auto;
          }
        }
      }

      &.dtrg-level-0, &.show {
        display: block !important;
      }

      &.dtrg-level-0 {
        td {
          &:nth-child(1) {
            width: 40% !important;
          }
        }
      }

      &.dtrg-level-1 {
        td {
          &:nth-child(1) {
            width: 40% !important;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            text-align: right !important;
          }
        }
      }
    }
  }

  &.alert-log-table-by-issue_type {
    thead {
      tr {
        th {
          width: 10% !important;

          &:nth-child(1) {
            width: 1% !important;
          }

          &:nth-child(2) {
            width: 29% !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          width: 10% !important;
        }

        &.dtrg-level-0 {
          td {
            &:nth-child(1) {
              width: 30% !important;
            }
          }
        }

        &.row {
          td {
            width: 10% !important;

            &:nth-child(1) {
              width: 2.6% !important;
            }

            &:nth-child(2) {
              width: 27.4% !important;
            }

            &:last-child {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.blocking-status-icon {
  position: relative;
  bottom: 3px;
  right: 5px;
}

.blocking-status-icon + .blocking-status-text {
  position: relative;
  top: -7px;
}

.report-screenshot {
  img {
    display: block;
    margin: 0 auto;
    border: 4px solid $steel;
  }
}

// Useful CSS for Modal Dialogs
.modal {
  z-index: 100000000;

  .modal-subtitle {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 90%;
    color: $steel;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
  }

  .recharts-wrapper {
    margin: 0 auto;
  }
}

.daterangepicker {
  table {
    tr {
      -webkit-touch-callout: none !important;
      -webkit-user-select: none !important;
      -khtml-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
      user-select: none !important;

      td {
        &.off {
          &.available {
            visibility: hidden;
          }
        }

        &.today {
          font-weight: bold !important;
        }
      }
    }
  }
}

.flag-icon {
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
  font-size: 175%;
  margin-right: 10px;
  position: relative;
  top: 5px;
  margin-top: 3px;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  background: white;
  display: inline-block;
}

.table-log {
  text-align: center;
  display: inherit;

  a {
    text-decoration: underline;
  }
}

.flag-icon {
  background-color: #FFF;
  border-radius: 4px;
  overflow: hidden;
  font-size: 175%;
  margin-right: 10px;
  position: relative;
  top: 5px;
  margin-top: 3px;
}

.modal-body {
  max-height: 450px !important;

  &--lrg {
    max-height: 650px !important;
  }
}

.report-table-row {
  a {
    color:  #fff;
  }
}

.highcharts-credits,
.highcharts-title,
.highcharts-yaxis,
.highcharts-xaxis {
  display: none;
}

.alert-modal-issue-title {
  padding-left: 20%;
}

.chart-wrapper {
  padding-top: 1em;
  max-height: 320px;

    .highcharts-axis-labels, .highcharts-xaxis, .highcharts-legend{
      display: block;
    }
}

.chart-wrapper-col {
  margin-left: 3%;
  padding-top: 1em;
  max-height: 320px;
}

.chart-wrapper-impressions {
  text-transform: none;
  .highcharts-axis-labels {
    display: block;
  }
}

.charts-compact {
  max-height: 330px;
  height: auto;
}

.chart-wrapper-blocking {
  margin-top: 3em;
  .highcharts-axis-labels {
    display: block;
  }
}

// timezone label above dashboard and alert log
.timezone-lbl {
  text-align: right;
  color: $steel;
  font-style: italic;
  font-size: 85%;
  width: 100%;
}

// publisher block table
.publisher-block-table {
    margin-top: 15px;
    width: 100%;
  
    thead {
      color: $steel;
      font-size: 85%;
      text-align: center !important;
  
      tr {
        th {
          position: relative;
          font-size: .875em !important;
          width: 12%;
          vertical-align: middle !important;
          
          & > span {
            text-align: center;
            display: block;
  
            i {
              padding-left: 5px;
            }
          }
  
          &:first-child {
            width: 3%;
          }
          &:nth-child(2) {
            width: 30%;
          }
  
          &.sortable {
            cursor: pointer;
  
            span {
              padding: 0 8px;
            }
  
            &:before {
              content: '\25B4';
              display: none;
              position: absolute;
              height: 12px;
              right: 0;
              top: 0;
              margin-top: 5px;
              margin-left: 1px;
              font-size: .8em;
            }
  
            &:after {
              content: '\25B4';
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              -o-transform: rotate(180deg);
              -ms-transform: rotate(180deg);
              transform: rotate(180deg);
              display: none;
              position: absolute;
              height: 12px;
              right: 0;
              bottom: 0;
              margin-bottom: 5px;
              font-size: .8em;
            }
  
            &.asc:before {
              display: inline-block;
              font-weight: bold;
              color: #711c1c;
            }
  
            &.desc:after {
              display: inline-block;
              font-weight: bold;
              color: #711c1c;
            }
          }
        }
      }
    }
  
    tbody {
      font-size: 85%;
      cursor: pointer;
  
      tr {
        &.alert-log-table-header {
          background-color: $white-three;
          font-weight: bolder !important;
  
          td {
            padding: .75rem 0 .75rem 20px !important;
          }
        }
  
        &.alert-log-table-subheader {
          background-color: $pale-grey;
  
          td {
            padding: .75rem 0 .75rem 20px !important;
          }
        }
  
        &.no-spark-line {
          td {
            padding: 10px 0;
          }
        }
  
        &.alert-log-table-issue {
        }
  
        border: 1px solid $toggle-inactive;
  
        td {
          text-align: right;
          width: 12%;
  
          span {
            padding: 0 5px;
          }
  
          &:first-child {
            width: 3%;
            text-align: left;
          }
          &:nth-child(2) {
            width: 25%;
            text-align: left;
            word-break: break-all;
          }
          &:nth-child(4) {
            text-align: center;
            font-size: 95%;
          }
          &:nth-child(5),
          &:nth-child(6) {
            text-align: center;
  
            .recharts-wrapper {
              position: relative;
              bottom: 10px;
              margin: 0;
              padding: 0;
              left: 30%;
            }
          }
          &:nth-child(8) {
            text-align: center;
            font-size: 90%;
            
            i {
              padding-right: 3px;
              color: $steel;
            }
          }
          &:nth-child(9) {
            span {
              padding-right: 15px;
            }
          }
  
          i {
            font-size: 125%;
            position: relative;
            right: 3px;
            top: 1px;
          }
        }
  
        &.alert-log-table-header,
        &.alert-log-table-subheader {
          td {
            &:first-child {
              position: relative;
              
  
              &:before {
                content: "\25B4";
                width: 8px;
                height: 8px;
                transform: rotate(90deg);
                position: absolute;
                right: 0;
                top: 40%;
                font-size: 1.25em;
              }
  
              &.open {
                &:before {
                  transform: rotate(180deg);
                  top: 70%;
                  left: 60%;
                }
              }
            }
          }
        }
  
        &.alert-log-table-subheader {
          td {
            &:first-child {
              &:before {
                font-size: 1.65em;
              }
            }
          }
        }
      }
    }
  
    &.alert-log-table-by-issue_type {
      thead {
        th {
          width: 10.285714285714286%;
  
          &:first-child {
            width: 3%;
          }
          &:nth-child(2) {
            width: 25%;
          }
        }
      }
  
      tbody {
        td {
          width: 10.285714285714286%;
  
          &:first-child {
            width: 3%;
          }
          &:nth-child(2) {
            width: 25%;
          }
        }
      }
    }
  }

// alert log table layout
table.alert-log-table {
  margin-top: 15px;
  width: 100%;

  thead {
    color: $steel;
    font-size: 85%;
    text-align: center !important;

    tr {
      th {
        position: relative;
        font-size: .875em !important;
        vertical-align: middle !important;
        
        & > span {
          text-align: center;
          display: block;
          padding: 10px 0;

          i {
            padding-left: 5px;
          }
        }

        &.sortable {
          cursor: pointer;

          span {
            padding: 0 8px;
          }

          &:before {
            content: '\25B4';
            display: none;
            position: absolute;
            height: 12px;
            right: 0;
            top: 0;
            margin-top: 5px;
            margin-left: 1px;
            font-size: .8em;
          }

          &:after {
            content: '\25B4';
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            display: none;
            position: absolute;
            height: 12px;
            right: 0;
            bottom: 0;
            margin-bottom: 5px;
            font-size: .8em;
          }

          &.asc:before {
            display: inline-block;
            font-weight: bold;
            color: #711c1c;
          }

          &.desc:after {
            display: inline-block;
            font-weight: bold;
            color: #711c1c;
          }
        }
      }
    }
  }

  &.alert-log-table-by-issue_type {

    tbody {
      td {
        &:last-child {
          text-align: center !important;
        }
      }
    }
  }

  tbody {
    font-size: 85%;
    cursor: pointer;
    
    tr {
      &.alert-log-table-header,
      &.alert-log-table-group {
        background-color: $white-three;
        font-weight: bolder !important;

        td {
          padding: .75rem 0 .75rem 20px !important;
        }
      }

      &.alert-log-table-subheader,
      &.alert-log-table-subgroup {
        background-color: $pale-grey;

        td {
          padding: .75rem 0 .75rem 20px !important;

          &:nth-child(6) {
            padding-left: 0px !important;
          }

          &:nth-child(2) {
            padding-left: 0px !important;
          }
        }

      }

      &.no-spark-line {
        td {
          padding: 10px 0;
        }
      }

      &.alert-log-table-row {
        .recharts-wrapper {
          margin: 0 auto;
        }
      }

      &.alert-log-table-issue {
        cursor: pointer;
      }

      border: 1px solid $toggle-inactive;

      td {
        text-align: right;

        span {
          padding: 0 5px;
          text-align: right;
        }

        &:first-child {
          text-align: left;

          span {
            text-align: left;
          }
        }
        .privacy-other-rows-span {
          word-break: normal !important;
          white-space: normal !important;
        }
        &:nth-child(2) {
          text-align: left;
          word-break: break-all;
          white-space: nowrap; 
          text-overflow:ellipsis; 
          overflow: hidden; 
          max-width:1px;
          padding-left: 0px !important;
        }
        &:nth-child(4) {
          text-align: center;
          font-size: 95%;

          span {
            text-align: center;
          }
        }
        &:nth-child(5),
        &:nth-child(6) {
          text-align: center;

          span {
            text-align: center;
          }

          .recharts-wrapper {
            position: relative;
            bottom: 10px;
            margin: 0;
            padding: 0;
            left: 30%;
          }
        }
        &:nth-child(8) {
          text-align: center;
          font-size: 90%;

          span {
            text-align: center;
          }
          
          i {
            padding-right: 3px;
            color: $steel;
          }
        }
        &:nth-child(9) {
          span {
            padding-right: 15px;
          }
        }

        i {
          font-size: 125%;
          position: relative;
          right: 3px;
          top: 1px;
        }
      }

      &.alert-log-table-header,
      &.alert-log-table-subheader{
        td {
          &:first-child {
            position: relative;
            

            &:before {
              content: "\25B4";
              width: 8px;
              height: 8px;
              transform: rotate(90deg);
              position: absolute;
              right: 0;
              top: 40%;
              font-size: 1.25em;
            }

            &.open {
              &:before {
                transform: rotate(180deg);
                top: 70%;
                left: 60%;
              }
            }
          }
        }
      }

      &.alert-log-table-subheader,
      &.alert-log-table-subgroup {
        td {
          &:first-child {
            &:before {
              font-size: 1.65em;
            }
          }
        }
      }
    }
  }
}

.no-padding {

  &--left {
    padding-left: 0;
  }
}

.icons-flex-block {
  flex-direction: row;
  justify-content: flex-end;
}

.redux-toastr { 
  .top-right:empty {
      width: 0px !important;
      height: 0px !important;
  }
}

.wide-select-text {
  width: 270px !important;
  margin-left: 0 !important;

  select {
    padding: 0;
  }

}

  .sticky-header {
  margin-left: 160px;
}

.no-zoom {
  width: 90%;
  height: 55%

}

.no-zoom-img {
    width: 100%;
    height: 100%;
}

.pub-block-table {
  i {
    padding-right: 3px;
    color: $steel;
  }
}

.flex-spacer {
	flex-grow: 1;
}

.property-filter  {
  margin-left: 0px;
  .input-group {
    margin-bottom: 10px;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Add some styling */
    
      display: block;
      width: 100%;
      max-width: 320px;
      float: right;
      height: calc(1.5em + 0.75rem + 4px);
      padding: 0px 24px 0px 12px;
      font-size: 16px;
      line-height: 1.75;
      color: #333;
      background-color: #eeeeee;
      background-image: none;
      border: 0 !important;
      -ms-word-break: normal;
      word-break: normal;
      
      &::-ms-expand {
        display: none;
      }
    }
  }

  .propertytype {
    position: relative;
  
    &:after {
        content: '\e903';
        font-family: 'icomoon' !important;
        color: $steel;
        right: 5px;
        height: 34px;
        padding: 10px 1px 0px 8px;
        position: absolute;
        pointer-events: none;
      }
  }
}

#propertySelection {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding-right: 20%;
  white-space: nowrap;
}

.ellipsis-style {
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow : ellipsis;
  white-space : nowrap;
  word-break: break-all;
  cursor: default;
}

.control-addproperty {
  margin-bottom: 0px !important;
}

.margin-right-100 {
  margin-right: 100px;
}

.sticky-bar {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 100px 20px 0;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 9999999;
}

.hover-text {
  text-transform:uppercase;
  line-height: 1.1;
  font-weight: 800;
  color: #CF2D4F;
  font-size: 20px;
  border-color:transparent;
  opacity: 0.9;
}

.button-cursor-wait {
  cursor: wait;
}
