#report-pane {

    .requested-report-header {
      font-weight: bold;
      padding-top: 5%;
    }

    td {
      font-weight: 400;
    }

    div.form-inline {
      padding: 5px 0;
      

      input[type='number'] {
        margin-left: .75em;
        margin-right: .75em;
      }
    }
  }

  .second-header {
    font-weight: 600;
  }

  .filter-wrapper {
    // margin-top: 3%;

    .top-line {
     padding-bottom: 2%;
    }
  }

  .form-impression {
    padding-top: 2%;

    span {
      margin-left: 10px;
    }
  }

  .actionable-cell {
    padding: 0;
    text-align: center;
  }

  .reports-header {
    width: 230px !important;
    margin-left: 0 !important;

    select {
      padding: 0;
    }
  }


  .report-table-row {
    button {
      margin-right: 10px;
    }
  }

  
.confirm-dialog--reportrequest {
  background: #FFF;
  border: 3px solid $confiant-purple;
  color: $confiant-purple;
  padding: 20px;
  width: 50%;
  font-weight: bold;
  align-items: center;
  display: inline-block;
  margin-left: 30%;
  
    .confirm-dialog-actions {
      text-align: right;
      margin-top: 20px;
  
      button {
        margin-left: 10px;
      }
    }
}
  
.confirm-dialog--reportrequested {
  background: #FFF;
  border: 3px solid $confiant-purple;
  color: $confiant-purple;
  padding: 20px;
  width: 60%;
  font-weight: bold;
  align-items: center;
  display: inline-block;
  margin-left: 30%;
  
  .confirm-dialog-actions {
    text-align: right;
    margin-top: 20px;
  
    button {
      margin-left: 10px;
    }
  }
}

.report-table-row {
  i {
    color: $secondary;
  }
}

.bs-tooltip-right, .bs-tooltip-left{
  ul {
    text-align: left;
    padding-left: 0;
  }

  li {
    text-align: left;
  }

  .arrow::before{
    border-right-color: #eaeaea;
    border-left-color: #eaeaea;
  }

  .tooltip-inner {
    background-color: #eaeaea ;
    color: #000000;
    text-align: left;
  }
  
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
    border-bottom-color: #eaeaea;
  }
  
  .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
    border-top-color:#eaeaea ;
  }
  
  .bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
    border-left-color: #eaeaea;
  }
  
  .bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
    border-right-color: #eaeaea;
  }

}

.bs-tooltip-right-start{

  .arrow::before{
    border-right-color: #eaeaea;
  }

  .tooltip-inner {
    min-width: 350px !important;
    background-color: #eaeaea ;
    color: #000000;
    text-align: left;
  }
  
  .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
    border-bottom-color: #eaeaea;
  }
  
  .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
    border-top-color:#eaeaea ;
  }
  
  .bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
    border-left-color: #eaeaea;
  }
  
  .bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
    border-right-color: #eaeaea;
  }

}

.border-bottom-ssp-report {
  border-bottom: 1px solid $steel;
}

#report-selection {
  padding-left: 0;

}

.report-type-filter {
  .input-group {
    margin-bottom: 10px;

    .input-group-text {
      background-color: white;
      border: none;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Add some styling */
    
      display: block;
      width: 100%;
      max-width: 320px;
      height: 100%;
      float: right;
      padding: 0px 24px 0px 12px;
      font-size: 16px;
      line-height: 1.75;
      color: #333;
      background-color: #eeeeee;
      background-image: none;
      border: 0 !important;
      -ms-word-break: normal;
      word-break: normal;
      
      &::-ms-expand {
        display: none;
      }
    }
  }


  .reporttype {
    position: relative;
    margin-left: auto;

    &:after {
        content: '\e903';
        font-family: 'icomoon' !important;
        color: $steel;
        right: 5px;
        height: 34px;
        padding: 10px 1px 0px 8px;
        position: absolute;
        pointer-events: none;
      }
  }

}

.filter_heading_actions{
  margin-bottom: 14%;
}

.report-warning {
  color: $secondary;
}