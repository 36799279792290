// Medium devices (tablets, less than 992px)
@include media-breakpoint-down(md) { 
  body {
    font-size: 14px;
  }

  h1, .h1 {
    font-size: 2rem;
  }

  h2 {
    &.page-sub-title {
      font-size: 1.1em;
      margin: 0;
  
      .icon-info {
        font-size: 1em;
        line-height: 1em;
        margin-left: 10px;
      }
    }
  }

  .sm-header-out {
    border-bottom: 1px solid rgba(151, 151, 151, 0.25);
  }

  .common-container {
    padding-left: 0;
  
    form {
      label {
        font-size: 1em;
      }
  
      .form-control {
        font-size: 1em;
      }
    }
  }
  .popover1{
    width: 225px;
  }
}


 // Small devices (landscape phones, less than 768px)
@include media-breakpoint-down(sm) { 
  .medium-main {
    width: 100%;
  }
  body {
    font-size: 14px;
  }

  h1, .h1 {
    font-size: 1.5rem;
  }

  .page-header-out {
    background: $white;
    padding: 0;
    
    h1 {
      font-size: 1.7145em;

      .small {
        font-size: 0.5em;
        margin-left: 5px;
      }
    }
    &.complex-header{
      h1{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 2rem;
          margin-bottom: -1rem;
          .header-text{
            display: inline-block;
          }
          .selectdiv{
            top: unset;
            line-height: 1;
            margin:0;
            &:after{
              padding-top: 0;
            }
            select{
              margin: 0;
              height: auto;
              line-height: 1;
            }
          }
      }
    }
  }

  h2 {
    &.page-sub-title {
      font-size: 1.1em;
      margin: 0;
  
      .icon-info {
        font-size: 1em;
        line-height: 1em;
        margin-left: 10px;
      }
    }
  }
  
  .common-container {
    padding-left: 0;
    padding-top: 0;

    .row-form-mb {
      margin-bottom: 15px;
    }
  
    form {
      label {
        color: $primary;
        font-weight: 600;
        font-size: 1.1em;
      }
  
      .form-control {
        padding: 0.5rem 0.7rem;
        font-size: 1em;
      }
    }
  }

  .m-mt-15 {
    margin-top: 15px;
  }

  //For modal popup
  .modal-dialog-centered {
    align-items: stretch;
    //min-height: calc(100% - 56px);
    //top: -28px;
    min-height: unset;
    margin: 0;
  }

  .modal-content {
    .modal-header {
      border-bottom: 1px solid $white-two;
      display: block;

      .back-link {
        font-size: 1.5rem;
        margin-bottom: 15px;
        font-weight: normal;
        display: block;
        vertical-align: middle;
        letter-spacing: 0.1px;
        color:#1a173b;

        span {
          font-size: 0.8rem;
          vertical-align: middle;
        }
		&:hover, &:active, &:visited{
			text-decoration: none;
		}
      }
      .close{
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
      }
    }

    .m-email-out {
      margin: 15px -15px 30px -15px !important;
      border-bottom: 1px solid $white-two;
      padding-bottom: 30px;
    }
  }

  .sidebar {
    width: 250px;
    top: 57px;
    padding-top: 15px;
    z-index: 1032 !important;

    &.sm-hidden {
      display: none;
    }
  }
  
  .sm-header-out {
    border-bottom: 1px solid rgba(151, 151, 151, 0.25);
  }

  .sm-text-center{
    text-align:center;
  }

  .toggle-out {
    display: inline-block;
  }

  .radio-on-off-out {
    display: none;
    &.no-hide{
      display: inline-flex;
    }
  }

  .mlr-xs-none {
    margin: 0;
    padding: 0;
  }
  .redux-toastr{
    .top-right{
      position: fixed !important;
      top: -10px !important;
      width: 100% !important;
      height: auto !important;
    }

  }
  $filter-collapse-border : 1px solid $white-two;
  .filters-collapse{
    margin-top: 10px;
  }
  .filters-collapse-brd-top{border-top:$filter-collapse-border;}
  .filters-collapse-brd-bottom{border-top:$filter-collapse-border;}
  //custowm width for properties page
  .custom-width-btn{
    width:171px;
  }
  .popover1{
    width: 40%;
  }
}


 // Small devices (landscape phones, less than 768px)
@include media-breakpoint-up(xs) { 

  .xs-mt-15 {
    margin-top: 15px;
  }

  .modal-dialog-centered{
    min-height: 100%;
    &:before{
      height: unset;
    }
  }
}

.alert-modal-providers-table tr {
  td {
    width: 25% !important;
    text-align: right !important;

    &:nth-child(1) {
      text-align: left !important;
    }

    :nth-child(4) {
      text-align: center !important;
    }
  }
 }

.alert-modal-providers-table tr {
  th {
    text-align: right !important;

    &:nth-child(1) {
      text-align: left !important;
    }
  }
}

.alert-log-category-row td {
   padding: 0 !important;
   vertical-align: middle !important;

   :nth-child(1), :nth-child(2) {
     padding: 0.75rem !important;
   }
 }

 td.text-right,
 th.text-right {
   text-align: right !important;
 }

 td.text-center,
 th.text-center {
   text-align: center !important;
 }

 tr.text-middle {
   vertical-align: middle !important;
 }


 @media (min-width: 2500px){
  .medium-main {
    .login-page {
      width: 75% !important;
    }
  }
}


 @media (min-width: 1700px) and (max-width: 2500px){
  .medium-main {
    .login-page {
      width: 80% !important;
    }
  }
}



@media (min-width: 1500px) and (max-width: 1700px){
  .medium-main {
      .login-page {
        width: 85% !important;
      }
  }
}

@media (min-width: 1200px) and (max-width: 1500px){
  .medium-main {
      .login-page {
        width: 90% !important;
      }
  }
}

@media (max-width: 1200px) and (min-width: 767px){
  .medium-main {
    .login-page {
      width: 70%;
    }

    @media (max-width: 1200px) and (min-width: 1000px){
      .login-page {
        width: 100%;
      }
    }
  }
}



@media (max-width: 767px) {
  .responsive-corner {
    display: none !important;
  }

  .properties-header {
    margin-left: 10% !important
  }
}

@media (max-width: 1700px) and (min-width: 1200px) {
  .padding-r-text {
    padding-right: 0 px !important;
  }
}

.medium-main {
  margin-left: 180px
}