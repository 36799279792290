.modal.fade .modal-dialog
{
    -moz-transition: none !important;
    -o-transition: none !important;
    -webkit-transition: none !important;
    transition: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;
}

.form-group {
  width: 100%;
}

.inline-block {
	display: inline-block;
}

.modal-dialog {
  margin: 0 auto;
}

.modal-content {
  @include border-radius(0);
  border-color: $white-two;
  font-size: 1em;

  #modal-header-title {
    background-color: $white-three;
  }

  .modal-header {
    border-bottom: none;

    h5, .h5 {
      font-size: 1rem;
      font-weight: 500;
      text-transform:uppercase;
    }

    button {
      padding-top: 1.2rem;

      &.close {
        font-size: 0.875rem;
      }
    }
  }
  .modal-footer{
	border-top:0;
  }
}
.modal-footer-center {
  justify-content: center !important; // Right align buttons with flex property because text-align doesn't work on flex items
}

@include media-breakpoint-down(md) {
	body.modal-open{
	  padding-right:0 !important;
	  .modal{
		top: 57px !important;
		padding-right: 2px !important;
	  }
	}
}

@media screen and (max-width: 2500px) {
  .modal {
    .modal-dialog {
        min-width: 700px;
    }
    &.sml-modal {
      .modal-dialog {
        min-width: 500px;
      }
    }
  }
}

@media screen and (max-width: 2500px) and (min-width: 1001px) {
  .modal {
    &.lrg-modal {
      .modal-dialog {
          min-width: 1000px;
      }
    }
  }
}

@media screen and (max-width: 1000px) and (min-width: 901px) {
  .modal {
    &.lrg-modal {
      .modal-dialog {
        min-width: calc(100vw - 20px);
        .modal-content {
          margin-top: -12%;
        }
      }
    }
  }
}

@media screen and (max-width: 900px){
  .modal{
    &.lrg-modal{
      .modal-dialog {
        min-width: 95%;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .modal{
    .modal-dialog {
        min-width: 95%;
        margin: 0 auto;
    }
    &.sml-modal, &.lrg-modal{
      .modal-dialog {
        min-width: 95%;
        .modal-content {
          max-height: calc(100vh - 136px);
        }
      }
    }
  }
}

#bannedDomainsModal{
  .modal-content {
    padding: 10px 15px;
    .modal-title{
      font-weight: 600;
    }
    .modal-body{
      padding-top: 30px;
      .faded-text{
        color: #788599;
        font-size: 17px;
        line-height: 1.4;
        margin-bottom: 18px;
      }
      .domain-list-container{
        background: #e5e5e5;
        padding: 10px 20px 20px 20px;
        margin-bottom: 25px;
        textarea{
          display: block;
          border:0;
          outline: 0;
          width: 100%;
          min-height:300px;
          background:none;
          &:focus, &:active{
            outline: 0;
          }
        }
      }
    }
  }
}


#hrapModal {

  .modal-header {
    padding-bottom: 10px !important;
    padding-top: 25px !important;

    .modal-title {
      padding-left: 8px;
      font-weight: 600 !important;
    }
    
    .close {
      font-size: 2rem !important;
      padding-right: 15px !important;
      padding-top: 0px !important;
      font-weight: 600 !important;
    }
  }

  .hrap-description {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 20px;
    color: $warm-grey;
    font-weight: 600;
  }

  .hrap-table-container{
    padding: 0 16px;

    .cell{
      margin-top: 0.5em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $warm-grey;
    }
  }

  .hrap-table-head{
    font-weight: 600;
    font-size: 1em;
    margin-bottom: 0.5em;
    padding: 0 30px 0 15px;
  }

  .hrap-table-body{
    font-size: 1em;
    max-height: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0 15px;
  }

}

#admModal{
  .modal-content {
    padding: 10px 15px;
    border-color: #b1b1b1;
    
    .modal-title{
      font-weight: 600;
    }
    .modal-header {
      background-color: #edebeb;
      button {
        font-size: 1.25rem;
        padding-top: 1rem !important;
      }
    }
    .modal-body{      
      padding-left: 0 !important;
      padding-right: 0 !important;

      .adm-container{
        background: #e5e5e5;
        padding: 10px 20px 20px 20px;
        margin-bottom: 15px;
        textarea{
          display: block;
          border:0;
          outline: 0;
          width: 100%;
          min-height:300px;
          background:none;
          &:focus, &:active{
            outline: 0;
          }
        }
      }
    }
    .modal-footer {
      padding-top: 0;
    }
  }
}

#bannedBrandsModal {
  padding-top: 0px;

  select {
    margin: 0px !important;
    border-color: #dfdfdf !important;
    border-radius: 0.3rem;
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .selectdiv:after {
    top: 2px;
    right: 6px;
  }

  label {
    color: #788599;
    // font-weight: 600;
    // line-height: 0.5;
    // font-size: 0.8em;
  }

  .button-labels {
    font-size: 0.9em;
    color: #788599;
    font-weight: 600;
    display: block;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .button-label-dnm {
    margin-top: -5px;
  }

  .body-container {
    max-height: calc( 95vh - 270px);
    overflow-x: hidden; 
    overflow-y: auto;

    .no-results {
      padding: 10%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .category-row {
    margin: 0px !important;
    flex-direction: row-reverse;
    line-height: 1.8;

  }
  
  .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .hovered-row {
    background-color:#F1F3F4;
  }

  #modal-search {
    background-color: white;
  }

  .input-group {
    width: 60%;

    .bar-input {
      width: 90%;
    }
  }

  .content-container{ min-height: 300px;}
}

#bannedCategoriesModal {
  min-height: 500px;
}

.button-container {
      width:200px;
      min-width: 200px;
      display: inline-block;

      .radio {
        width: 33%;
        float: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 5%
      }

}

.react-confirm-alert-body-element {
  .modal {
    z-index: auto;
  }

  .modal-backdrop {
    z-index: auto;
    opacity: 0;
  }

  .react-confirm-alert-overlay {
    z-index: 1000;
  }
}


.possible-match {
  color: #788599;
  font-size: smaller;
}

.cursor-pointer {
  cursor: pointer;
}

// #bannedBrandsModal {
//   .modal-footer {
//     padding-top: 3rem;
//   }
// }
#bannedCatsModal {
  max-height: 650px !important;
  padding-top: 0px;
  
  select {
    margin: 0px !important;
    border-color: #dfdfdf !important;
    border-radius: 0.3rem;
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .selectdiv:after {
    top: 2px;
    right: 6px;
  }

  label {
    color: #788599;
    font-weight: 600;
    // line-height: 0.5;
    font-size: 0.8em;
  }

  .button-labels {
    font-size: 0.9em;
    color: #788599;
    font-weight: 600;
    display: block;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .button-label-dnm {
    margin-top: -5px;
  }

  .body-container {
    max-height: 450px;
    overflow-x: hidden; 
    overflow-y: auto;

    .no-results {
      padding: 10%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .category-row {
    margin: 0px !important;
    flex-direction: row-reverse;
    line-height: 1.8;

  }
  
  .row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .hovered-row {
    background-color:#F1F3F4;
  }

  #modal-search {
    background-color: white;
  }

  .input-group {
    width: 60%;

    .bar-input {
      width: 90%;
    }
  }

  .content-container{ min-height: 300px;}
}

.pagination-wrapper {
  
  ul {padding-left: 2rem;}
  li { padding-right: 3px;}

}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 4px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span,
.pagination > li > button {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: $secondary;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  cursor: pointer;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > li > button:focus {
  z-index: 2;
  color: #20072e;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > button,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > button:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.pagination > .active > button:focus {
  z-index: 3;
  color: #fff;
  background-color: $secondary;
  border-color: $secondary;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination > .li > span,
.pagination > .li > span:hover,
.pagination > .li > span:focus {
  display: inline-block;
}

.pagination-wrapper .pagination button {
  font-weight: bold;
}

.modal-opac {
  opacity: 0.8;
}