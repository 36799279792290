.selectdiv {
  &.no-border.inline {
    margin: 0;
    width: unset;
    top: 0;
    select {
      padding: 0 30px 0 0;
      margin: 0;
    }
    @include media-breakpoint-up(sm) {
      &+.no-border.inline {
        margin-left: 32px;
      }
    }
  }
}

.m-lr-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr-30 {
  margin-left: 30px;
  margin-right: 30px;
}
@include media-breakpoint-down(sm){
  .m-lr-10, .m-lr-15, .m-lr-30{
    margin-left: 0;
    margin-right: 0;
  }
  .row.m-lr-10{
    margin-left: -15px;
    margin-right: -15px;
  }
}
.dashboard-container{
  @include media-breakpoint-down(sm){
    .row.mt-30{
      margin-top: 0;
    }
  }
}

.panel {
  background-color: $white;
  border: solid 1px $white-two;

  @include media-breakpoint-down(sm){
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  padding: 20px;
  color: $primary;
  font-size: 14px;

  &.panel-full-width {
    &.row {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 0;
    }
  }

  &.fixed-size {
    height: 186px;
    box-sizing: content-box;
  }
  &.fixed-size-large {
    height: 274px;
    @include media-breakpoint-down(sm){height: auto;}
  }
  &.full-height {
    height: 100%;
  }
  &.shaded {
    background-color: $white-two;
    padding: 20px 10px 10px 10px;
    @include media-breakpoint-down(sm){margin: 0 -15px;}
  }
  &.wide-content {
    padding-left: 0;
    padding-right: 0;
  }
  &.p-30 {
    padding: 30px 30px 15px 30px;
  }
  .top-shifted.row {
    margin-top: 2em;
  }
  .caption,
  .value {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
  }
  .caption{
    white-space: nowrap;
    &--impressions {
      font-size: 32px;
      margin-bottom: 0.5em;
      text-align: center;
      text-transform: uppercase;

    }
    .no-linked-alert{
      font-size: 1.2em;
    }
  }
  @media (min-width: 1200px) and (max-width: 1400px){
    .caption--repsonsive {
      margin-top: 0.5rem;
      font-size: 24px;

    }
  }
  .value {
    font-size: 36px;
  }
  .gauge {
    margin-top: 9px;
    text-align: center;
    font-size: 16px;
    .circle-background {
      stroke: $darkish-purple;
      opacity: 0.3;
    }
    .circle-progress {
      stroke: $darkish-purple;
    }
    .circle-text {
      font-size: 1.5em;
      font-weight: 700;
      fill: $primary;
      &--impressions {
        font-size: 21px;
        font-weight: 500;
        fill: #9590B0;

        &--smaller {
          font-size: 18px;
          font-weight: 500;
          fill: #9590B0;
        }
      }
    }
    .circle-subtext {
      font-size: 0.8em;
    }
  }
  .gauge-row {
    margin-bottom: 10px;
  }
}

@mixin hatch($color) {
  background-color: transparent;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 1px, $color 1px, $color 2px, transparent 2px);
  background-size: 3px 3px;
}

.chart-wrapper-ssp {
  margin-top: 3em;
  margin-bottom: 1em;
}

.chart-wrapper-blocking {
  >div,
  .highcharts-container,
  svg {
    overflow: visible !important;
  }

  .highcharts-legend {
    display: block !important;
    top: -10px;
  }

  ul.toggle-charts {
    margin: 0 auto;
    text-align: center;

    li {
      line-height: 1px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: $toggle-inactive;
      margin: 0 2px;
      cursor: pointer;
    }
    li.active {
      background-color: $toggle-active;
    }
  }
}

.legend {
  font-size: 12px;
  display: inline-block;
  padding: 0  0 0 1.8em;
  position: relative;
  &:before {
    content: " ";
    display: inline-block;
    background: red;
    width: 1em;
    height: 1em;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5em;
  }
  &.current-value {
    &:before {
      background: $dusky-blue;
    }
  }
  &.range-value {
    &:before {
      @include hatch($black);
    }
  }
  &+.legend{
    margin-left: 1.8em;
  }
}

.histo-gauge {
  font-size: 16px;
  color: $black;
  line-height: 16px;
  .caption {
    text-align: left;
  }
  .bar {
    display: inline-block;
    border-radius: 2px;
    height: 1em;
  }
  .value {
    padding-left: 0.3em;
    font-size: 1em;
    mix-blend-mode: difference;
  }
  .gauge-value {
    text-align: left;
    .bar {
      background: $dusky-blue;
    }
  }
  .gauge-range {
    text-align: right;
    .bar {
      background-color: transparent;
      @include hatch($black);
    }
  }
  &.combined {
    .gauge-block {
      min-width: 60px;
      height: 36px;
      position: relative;
      padding-top: 3px;
      display: inline-block;
      .gauge-value {
        //display: inline-block;
        //position: absolute;
        float: left;
        .bar {
          color: $white;
          height: auto;
          display: inline-block;
          line-height: 30px;
          font-size: 20px;
          height: 30px;
          .value {
            &::after {
              font-size: 12px;
              content: "%";
            }
          }
        }
      }
      .gauge-range {
        //display: inline-block;
        float: left; //position: absolute;
        top: 0;
        margin-top: -3px;
        .bar {
          height: 36px;
          width: 100%;
          @include hatch($darkish-purple);
          border-left: 2px solid $darkish-purple;
          border-right: 2px solid $darkish-purple;
        }
      }
    }
    .text-block {
      margin-left: 6px;
      display: inline-flex;
      height: 36px;
      vertical-align: top;
      justify-content: center;
      flex-direction: column;
      .info {
        font-size: 12px;
        color: $darkish-purple;
      }
    }
  }
}

.dashboard-table {
  thead {
    color: $primary;
    font-size: 11px;
    letter-spacing: 1.6px;
    line-height: 1.09;
    th {
      border: none;
      border-bottom: 1px solid $steel;
      &:first-child {
        min-width: 125px;
        padding-left: 30px;
      }
      &:last-child {
        max-width: 160px;
        padding-right: 25px;
      }
      &.sortable {
        cursor: pointer;
      }
    }
  }
  tbody {
    font-size: 14px;
    color: $primary;
    tr {
      td {
        border: none;
        &:first-child {
          padding-left: 30px;
          opacity: 1;
        }
        &:last-child {
          padding-right: 30px;
        }
      }
      &:nth-child(odd) {
        background-color: $pale-grey;
      }
    }
  }
}

.histogram {
  @include media-breakpoint-down(sm){
    margin-top: 30px;
  }
  .histogram-row {
    &:nth-child(odd) {
      background-color: #fafbfc;
    }
    &--hovered {
      background-color: #F1F3F4;
    }
    &.axis-row {
      border-top: 1px solid $steel;
      height: auto;
      @include media-breakpoint-down(sm){
        margin-top: 10px;
      }
      .histgramm-text {
        height: auto;
      }
    }
  }
  .histgramm-text {
    padding-left: 15px;
    line-height: 1.34;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    @include media-breakpoint-down(sm){height: auto; padding: 0;}
    .warning {
      font-weight: 700;
      color: $confiant-red;
    }
    .warning-ssp {
      font-weight: 700;
      color: $confiant-red;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden
    }
    .name {
      color: $black;
    }
    .name-ssp{
      color: $black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden
    }
    .date {
      color: $steel;
    }
  }
  &.ssp {
    .histgramm-text {
      height: 70px;
      .name {
        font-weight: bold;
      }
    }
  }
  .bar {
    height: 30px;
    border-radius: 2px;
    background-color: $dusky-blue;
    margin: 10px 0;
    @include media-breakpoint-down(sm){
      margin-bottom: 0;
      margin-top: 10px;
    }
  }
  &.ssp {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .axis {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(sm){
      padding-top: 5px;
    }
    .tick {
      font-size: 11px;
      letter-spacing: 1.6px;
      line-height: 1.09;
      color: $steel;
    }
  }
}

.plot {
  &.top-shifted {
    margin-top: 3.5em;
  }
  .plot-caption {
    font-size: 12px;
    margin-bottom: 20px;
  }
  .plot-area {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    margin: 0.5em 0;
    border-bottom: 1px solid $steel;
    padding-bottom: 15px;
    .plot-canvas {
      width: 80%;
      height: 125px;
    }
  }
  .y-axis {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1em;
    font-size: 12px;
    color: $steel;
    text-align: right;
    margin: -0.4em 0;
    padding: 0 1em;
  }
  .x-axis {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin-left: 15%;
    margin-top: 10px;
    line-height: 1em;
    font-size: 12px;
    color: $steel;
    .tick {
      text-align: center;
      &:first-child {
        margin-left: -2%;
      }
      &:last-child {
        margin-right: -5%;
      }
    }
  }
}
.selector-list{
  list-style: none;
  margin: 5px 0 0 0;
  padding: 0;
  &>li{
    display: inline-block;
    margin: 0 5px;
  }
  .indicator{
    display: inline-block;
    width: 12px;
    height: 12px;
    border:1px solid black;
    border-radius: 50%;
    background-color: transparent;
  }
  .active .indicator{background-color: $black;}
}


//This whould be removed
.custom-popover{
  .popover{
    .popover-body{
      color:white;
      background-color: $primary;
    }
  }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after{
    border-top-color: $primary;

  }
  .popover1.bs-popover-right, .popover1.bs-popover-auto[x-placement^="right"] {
    margin-left: -0.1rem;
}
}

.dashboard-table {
  .table-fixed {
    thead, tbody {
      tr {
        display: block !important;
        border-bottom: 1px solid #788599;

        th, td {
          width: calc(100% / 8) !important;
          min-width: calc(100% / 8) !important;
          max-width: calc(100% / 8) !important;
          display: block !important;
          border-bottom: none;
        }
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.bt-none {
  border-top: none !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.table-toggle {
  color: $primary
}

@media (min-width: 768px) and (max-width: 1024px){
  .row .col-8 {
    padding: 10px;
  }
}

.table-dashboard-responsive {
  display: table;
  max-height: 445px;
  overflow: auto;
  width: 100%;
  table-layout: fixed;

  th, td {
    padding: 0.75rem;
  }

  @media (max-width: 1366px) {
    max-width: 1068px;
    table.table-borderless tbody {
      font-size: 1.135em;

      .small-font {
        font-size: .7em !important;
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 783px;
  }

  @media (max-width: 767px) {
    max-width: 704px;
  }

  table td.td-padding4 {
    padding: 4px !important;
  }

}

.unique-issue {
  cursor: pointer;
}

.flex-panel {
  font-size: 14px;
  background-color: $white;
  border: solid 1px $white-two;

  @include media-breakpoint-down(sm){
    border-left: none;
    border-right: none;
    border-bottom: none;
  }
}

.flex-container {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-left: 3%;
  height: 100%;
}

.flex-item {
  margin: 1%;
  box-sizing: border-box;
  justify-content: space-between;
  width: 30%;
  display:flex;
  font-size: 16px;
}

.flex-item>div {
  border: 1px solid white;
  flex: 1 1 auto;
  margin: auto;
}

.flex-text {
  text-align: center;
  text-transform: uppercase;
}

.block-numbers {
  font-size: 28px;
  font-weight: 600;
  color: $primary;
}


.ssp-dashboard-table {
  thead {
    color: $primary;
    font-size: 11px;
    letter-spacing: 1.6px;
    line-height: 1.09;
    th {
      border: none;
      border-bottom: 1px solid $steel;
      &:first-child {
        min-width: 125px;
        padding-left: 30px;
      }
      &:last-child {
        max-width: 160px;
        padding-right: 25px;
      }
      &.sortable {
        cursor: pointer;
      }
    }
  }
  tbody {
    font-size: 14px;
    color: $primary;
    tr {
      td {
        border: none;
        &:first-child {
          padding-left: 30px;
          opacity: 1;
        }
        &:last-child {
          padding-right: 30px;
        }
      }
      &:nth-child(odd) {
        background-color: $pale-grey;
      }
    }
  }
}

.ssp-table-dashboard-responsive {
  display: table;
  max-height: 445px;
  overflow: auto;
  width: 100%;

  th, td {
    padding: 0.75rem;
  }

  @media (max-width: 1366px) {
    max-width: 1068px;
    table.table-borderless tbody {
      font-size: 1.135em;

      .small-font {
        font-size: .7em !important;
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 783px;
  }

  @media (max-width: 767px) {
    max-width: 704px;
  }

  table td.td-padding4 {
    padding: 4px !important;
  }

}

.ssp-issue-breakdown-chart {
  margin-top: 3%;

  .highcharts-container {
    position: absolute;

    .highcharts-legend {
      display: block;
    }
  }
}

.padding-b-20 {
  padding-bottom: 20px;
}

.padding-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.ssp-dashboard-title {
  color: $steel;
  text-transform: uppercase;
  font-weight: bold;
}

.display-none {
  display: none
}

.display-block {
  display: block
}

.modal-ssp-immunity {
  .modal-content {
    border: 3px solid $secondary;

    .modal-header {
      padding: 0.3rem 0.5rem;

        button { 
            font-size: 20px;
            cursor: pointer;
            padding-right: 1.2rem;
        }
        
        .close {
          font-weight: 500;
          font-size: 1.6rem;
        }
    }

    .modal-body {
      color: $primary;

      .messaging {
        text-align: center;
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
}
}