.tos-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: -95px;

  a {
    color: #20072e;
  }

  img.logo {
    padding-left: 66px;
  }

  .p-top-5 {
    padding-top: 5px;
  }

  .p-top-15 {
    padding-top: 15px;
  }

  .m-top-10 {
    margin-top: 10px;
  }
}