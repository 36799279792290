// settings page blocking preferences modal - for mobile
@include media-breakpoint-down(sm) {  
  #blockingPreferencesInfoMenu{
    //top: 26px !important;
    transform:none !important;
    //width:300px;
    position: fixed !important; 
    left:0!important;
    top:57px!important;
    width: 100%;
    height: calc(100% - 57px);
    overflow-y: scroll;
    margin: 0;
  }

  .dropdown.dropdown-big .dropdown-menu::before{
	left: calc(71% - 10px); 
  }
}
.linked-accounts-table{
  .btn-link{
    font-size: 0.875em;
    padding: 0;
    vertical-align: baseline;
    line-height: calc(1.5em + 0.75rem + 2px);
    @media (min-width: 840px) {
      font-size: 0.75rem;
    }
  }
}
.dropdown.dropdown-big.bp_dropdown .dropdown-menu{
  left:-235px!important;
  &::before{
    left: calc(59% + 0px);
  }
}

.toggle-onoff {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-weight: bold;
  font-size: 14px;
  color: $white;
  vertical-align: sub;
}

span {
  text-align: center;
}

.edit-icon {
  margin-left: 2%;
  vertical-align: sub;
  color: $primary;
}

.has-error {
  >input {
    border-color: #a94442;
  }
  >span {
    color: #a94442;
    margin-left: 1%;
  }
}

.cancel-button {
  background-color: transparent;
  color: $primary;
  border: none;
  margin-left: 1%;
}

.submit-button {
  background-color: transparent;
  color: $teal;
  border: none;
}

.editable-cell {
  .invalid-feedback {
    text-align: left;
  }
}

.control-switch {
  margin-left: 1%;
  margin-top: 3%;
}

.email_notification {
  span {
    text-align: left;
  }
}

.key-trigger {
  position: absolute;
  top: 30%;
  right: 1.2rem;
  color: #666;
  z-index: 9999;
  user-select: none;
}

.setting-input {
  width: 90px;
}

.text-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  span{
    line-height: calc(1.5em + 0.75rem + 2px);
    @media (min-width: 840px) {
      font-size: 0.75rem;
    }
  }
}

.setting-no-toggle {
  width: calc(1.25rem + 60px);
}

.settings-table {
  td {
    vertical-align: inherit;
  }
}

.email-pref-table {
  th {
    padding-top: 45px !important;
  }

  .btn {
    i {
      color: $secondary;
    }
  }
}

.toggle-column {
  padding-left: 2% !important;

  &--release {
    padding-left: 4% !important;
  }
}
form > div {
  margin-bottom: 10px;
}

form > div > label {
  display: block;
}
[data-feedback].error {
  color: #dc3545;
}
[data-feedback].warning {
  color: orange;
}
[data-feedback].info {
  color: blue;
}
[data-feedback].when-valid {
  color: green;
}
