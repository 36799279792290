@media (min-width: 770px) {
  .filter-bar {
    display: block !important;
    width: 100%;
  }
}

.filter-bar {
  width: 100%;
}

.alert-log-filter_heading {
  margin-bottom: 0.5rem;
}

.select-width {
  max-width: 260px;
  min-width: 160px;
}

.issue-tags {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
}

.items {
  display: inline-block;
  padding: 2px;
  border: 1px solid blue;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.actions-wrap {
  > div {
    display: inline-block;
    position: relative;

    &.date-filter-container {
      .react-bootstrap-daterangepicker-container {
        padding-left: 0 !important;
      }

      .btn {
        background: transparent;
        padding-left: 0;
        font-size: 0.7rem;

        &:focus,
        &:active,
        &:hover {
          box-shadow: 0 0 0 red;
          border-color: transparent;
        }

        span {
          color: #788599;
        }

        .fa-calendar {
          margin-right: 5px;
          color: $black;
        }

        .icon-down-arrow {
          font-size: 12px;
          color: $black;
        }
      }
    }

    &.submit-filters-container,
    &.reset-filters-container {
      margin-right: 5px;
      white-space: nowrap;

      .reset-filters {
        position: absolute;
        bottom: -25px;
        left: 0px;
        cursor: pointer;
        font-weight: 700;
        color: #000;
      }

      button {
        font-size: 0.8rem;
        height: 40px;
        margin: 5px 0px;
      }
    }

    &.export_csv_container {
      button {
        font-size: 0.7rem !important;
      }
    }
  }
}
.filter-bar-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.reset-filters-container {
  padding-left: 5px;
  margin-left: auto;

  a {
    font-weight: normal;
    text-decoration: underline;
  }
}

.container {
  display: flex;
}

.container > div:last-child {
  margin-left: auto;
}

.image-container {
  height: 40px;
  margin: 5px 0px;

  &.download {
    color: $primary;
    cursor: pointer;
  }
}

.react-select-container {
  display: block;
  width: 100%;
  max-width: 320px;
  height: 40px;
  float: right;
  margin: 5px 0px;
  font-size: 16px;
  line-height: 1.75;
  color: #333;
  background-color: #ffffff;
  background-image: none;
  -ms-word-break: normal;
  word-break: normal;
  min-width: 260px;
}

.react-select__control {
  border: 1px solid #979797 !important;
  height: 40px !important;
}

.tag-list-container {
  flex-direction: row-reverse;
  margin-top: 5px;

  .clear-container {
    position: relative;
    margin-bottom: 2%;

    .link-container {
      &--pos {
        position: absolute;
      }
      bottom: 0;
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.row-block {
  // padding-right: 10%;
  display: block;
  margin-top: 20px;
  &--noissue {
    margin-top: 0;
  }
}

.spec-items {
  display: inline-block;
  padding: 2px;
  border: 1px solid #E6E6E6;
  border-radius: 15px;
  margin-right: 5px;
  cursor: pointer;
  background-color: #E6E6E6;
}

.spec-tags {
  ul {
    padding-inline-start: 0;
  }
  margin-left: 15px;
}

// scan log table layout
table.scan-log-table {
  margin-top: 15px;
  width: 100%;
  table-layout: fixed;

  thead {
    color: $steel;
    font-size: 85%;
    text-align: center !important;

    tr {
      th {
        position: relative;
        font-size: 0.875em !important;
        vertical-align: middle !important;
        text-align: center !important;

        &:nth-child(2) {
          text-align: left !important;

          span {
            text-align: left;
          }
        }

        &:nth-child(4) {
          text-align: left !important;
        }

        & > span {
          text-align: center;
          display: block;
          padding: 0 0 0 20px;

          i {
            padding-left: 5px;
          }
        }
        &.sortable {
          cursor: pointer;

          &:before {
            content: "\25B4";
            display: none;
            position: absolute;
            height: 12px;
            right: 0;
            top: 0;
            margin-top: 5px;
            margin-left: 1px;
            font-size: 0.8em;
          }

          &:after {
            content: "\25B4";
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            display: none;
            position: absolute;
            height: 12px;
            right: 0;
            bottom: 0;
            margin-bottom: 5px;
            font-size: 0.8em;
          }

          &.asc:before {
            display: inline-block;
            font-weight: bold;
            color: #711c1c;
          }

          &.desc:after {
            display: inline-block;
            font-weight: bold;
            color: #711c1c;
          }
        }
      }
    }
  }

  tbody {
    font-size: 85%;

    tr {
      &.scan-log-table-header,
      &.scan-log-table-group {
        background-color: $white-three;
        font-weight: bolder !important;
        cursor: pointer;

        td {
          padding: 0.75rem 0 0.75rem 20px !important;
        }
      }

      &.scan-log-table-subheader,
      &.scan-log-table-row {
        background-color: $pale-grey;

        td {
          padding: 0.75rem 0 0.75rem 20px !important;
          text-align: center;

          &:nth-child(8) {
            padding-right: 20px !important;
          }
        }
      }

      &.scan-log-table-row {
        cursor: pointer;
      }

      border: 1px solid $toggle-inactive;

      td {
        span {
          display: block;
          padding: 0 5px;
        }

        &:first-child,
        &:nth-child(2) {
          text-align: left;

          span {
            text-align: left;
          }
        }
        &:nth-child(3) {
          padding-right: 8px;
          text-align: right;

          span {
            text-align: center;
          }
        }

        &:nth-child(8) {
          text-align: center;
          font-size: 90%;

          span {
            text-align: center;
          }

          i {
            padding-right: 3px;
            color: $steel;
          }
        }

        i {
          font-size: 125%;
          position: relative;
          right: 3px;
          top: 1px;
        }
      }

      &.scan-log-table-header {
        td {
          &:first-child {
            position: relative;

            &:before {
              content: "\25B4";
              width: 8px;
              height: 8px;
              transform: rotate(90deg);
              position: absolute;
              right: 0;
              top: 40%;
              font-size: 1.25em;
            }

            &.open {
              &:before {
                transform: rotate(180deg);
                top: 70%;
                left: 50%;
              }
            }
          }
        }
      }
    }
  }
}
