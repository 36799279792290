.modal-90w {
    width: 90%;
    max-width: none !important;

    .modal-header {
        background-color: $primary;
        padding: 5px 5px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .modal-content {
        min-height: 700px;

        .modal-header {
            .close {
                opacity: 1;
            }
            button {
                color: white;
                text-transform: uppercase;
                font-size: 20px;

                span {
                    text-transform: uppercase;
                }
            }
        }
    }

}

.leftpane--row {
    margin: 0 !important;
    &--scan {
        padding-bottom: 2%;
    }

    &--last {
        margin: 0 !important;
        padding-bottom: 0;
    }

    &.blocking {
        padding-top: 5px;
        &:first-of-type {
            padding-top: 0;
        }
    }

    &.scan-report {
        padding-bottom: 2%;
    }


}

.last-subtitle {
    border-bottom: 0;
}

.scanlog {
    font-size: 14px !important;
}

.leftpane-subtitle {
    color: #788599;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    margin-left: 5px;
    margin-right: 15px;
    padding-bottom: 15px;
    padding-top: 15px;

    &.entity {
        padding-top: 0 !important;
        padding-bottom: 0.5rem !important;
    }

    &.leftpane-subtitle-entity {
        padding-bottom: 0.5rem !important;
        padding-top: 1rem !important;
    }

    &--rest {
        padding-left: 0;
        text-transform: uppercase;
        font-size: 12px;
        &.scanlog {
            font-size: 13px !important;
        }

    }

    &--second {
        color: $primary;
        text-align: right;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
        &.scanlog {
            font-size: 13px !important;
            word-break: break-all;
        }
    }

    &--last {
        border-bottom: 0;
        padding-bottom: 5px;

        &.buttons {
            text-align: center;
        }

    }

    &.other-issues {
        border-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        text-transform: uppercase;
        font-size: 12px;
    }
}

.leftpane-subtitle-no-border {
    color: #788599;
    font-weight: 600;
    border-bottom: 0;
    margin-left: 5px;
    margin-right: 15px;
    padding-bottom: 15px;
    padding-top: 15px;

    &--rest {
        padding-left: 0;
        text-transform: uppercase;
        font-size: 12px;
    }

    &--second {
        color: $primary;
        text-align: right;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
    }

    &--last {
        border-bottom: 0;

    }
}

.leftpane-button {
    margin: 0 auto;
}

.leftpane-list {
    list-style: none;
    color: $primary;
    padding-top: 5px;
    font-weight: 500;
    font-size: 12px;

    &--resources {
        list-style: none;
        color: #788599;
        padding-left: 0 !important;
        text-transform: none !important;
        padding-top: 10px !important;
        font-weight: 500;
        font-size: 12px;
    }
}

#creative-report-title {
    text-transform: uppercase;
    color: white;
    margin-left: 10px;
    font-size: 16px;
}

.left-pane {
    &--icon {
        padding: 0;
        // i {
        //     width: 30px;
        //     height: 30px;
        // }
    }


    &--title {
        font-weight: bold;
        color: $primary;

        .row {
            margin-left: 8px;

            &.second {
                position: relative;
                bottom: -2px;
            }
        }
    }
}

#creative-report-shared {
    margin: -20px 20px 0 -160px;
}

.modal-body {
    max-height: 90% !important;
}

.modal-body, #creative-report-shared {
    & .nav-tabs {
        border-bottom: 1px solid #00d090;
    }

    & .privacy {
        border-color: #e3a342;
        .nav-link.active {
            background-color: #e3a342;
            border-bottom: 1px solid #e3a342;
            color: white;
        }
    }

    & .nav-link {
        background-color: #c3cdd8;
        color: white;
    }

    .nav-link.active {
        background-color: #00d090;
        border-bottom: 1px solid #00d090;
        color: white;
    }
}

.second-tab-titles {
    text-transform: uppercase;
    color: $primary;
    font-weight: bold;
}

.impacted-provider-table {
    text-transform: none;
    font-weight: normal;

    tr {
        text-align: right !important;
    }

    td {
        text-align: right !important;
        font-size: 12px;
    }

    .table-footer {
        text-align: center !important;
    }

    .table-toggler {
        color: #788599;
    }
}

.notitle-text {
    text-transform: none;
    font-weight: normal;
    color: black;
    font-size: 12px;
}

.network-load {
    text-transform: none;
    font-weight: normal;
    margin-left: 5%;
    font-size: 12px;
}

.heaviness-title {
    text-transform: none;
    font-weight: normal;
    margin-left: 2%;
    font-size: 14px;
}

.heaviness-link {
    text-decoration: underline;
}

.creative-report-tabs {
    .nav-item {
        margin-right: 2px;
    }
}

.text-url
{
    text-transform: none;
    font-weight: normal;
    color: black;
    background-color: rgb(241, 241, 241);
    padding: 15px;
    margin-right: 20px;
    word-break: break-all;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &.tab {
        min-width: 30%;
        max-width: 60%;
    }

    >i {
        margin-left: auto;
        margin-top: 1%;
        color: $primary;
        cursor: copy;
    }

    > span {
        font-size: 0.85em;
    }
}

.no-data {
    font-style: italic;
    text-transform: none;
    font-weight: normal;
    color: #797d82;
    background-color: #f1f1f1;
    padding: 15px;
    margin-right: 100px;
}

.copied-text, .copied-text-report {
    display: flex;
    flex-direction: row-reverse;
    font-size: 11px;
    text-transform: none;
    color: red;

    &.tab {
        margin-right: 0;
        padding-top: 15px;
    }
}

.copied-text {
    padding-top: 3px;
    margin-right: 20px;
    text-align: right;
}

.copied-text-report {
    justify-content: center;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.resources {
    margin-left: 5%;

    >a {
        font-size: 0.85em;
    }
}

.modal-open {
    .tooltip {
        z-index: 9999999999999 !important;
    }

    .tooltip-inner {
        background-color: #eaeaea ;
        color: #000000;
        text-align: left;
      }

      .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-bottom .arrow::before{
        border-bottom-color: #eaeaea;
      }

      .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-top .arrow::before{
        border-top-color:#eaeaea ;
      }

      .bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-left .arrow::before{
        border-left-color: #eaeaea;
      }

      .bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-right .arrow::before{
        border-right-color: #eaeaea;
      }


}

.adm-markup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    button {
        width: 40%;
    }
}

.scan-report-share {
    display: flex;
    justify-content: center;

    button {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
}

.expandable-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    .filters {
        color: #788599;
        cursor: default;
    }
}

.security-quality {
    .status{
        div {
            &:last-child {
            color: red;
        }}
    }

}

.truncate-text {
  text-align: left;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;

  &.tab {
      width: 90%;
  }
}

.pb-15 {
    padding-bottom: 15px
}

.threat-desc {
    text-transform: none;
    color: $secondary;
    font-weight: normal;
}

.pt-10 {
    padding-top: 10px;
}

.pr-30 {
    padding-right: 30px;
}

.ss-img {
    max-width: 60%;
    height: auto;
    text-transform: none;

    &.landing {
        max-width: 100%;
    }
}

.truncate-text-leftpane {
    text-align: right;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.no-data-text {
    font-style: italic;
    margin-left: 5%;
    color: #797d82;
    text-transform: none;
    font-weight: normal;
}

.buttons-group {
    display: flex;
    justify-content: space-evenly;
    margin: 0 5%;
}

.text-container {
    display: flex;
    justify-content: center;
    margin-right: 15px;
}

.no-margin {
    margin-left: 0 !important;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1031;
    background-color: rgba(0,0,0,0.8)
}

.modal-box {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    overflow-y: auto;
    // box-shadow: 0 0 10px rgba(0,0,0,0.25);
    z-index: 2000;
    // padding: 40px;
}

.modal-box-content {
    margin: 20px;
    margin-top: 5px;
}
#demo {
    cursor: zoom-in;
}

.popup-image {
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.tab-landing {
    padding-top: 15px;
    max-width: 25%
}

.notab-landing {
    padding-bottom: 15px;
}

.p-top-5 {
    padding-top: 5px;
}

.no-list-style {
    list-style: none;
}
