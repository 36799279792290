.creative-search-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    

    .bar {
        border: 1px solid #eeeeee !important;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        width: 50%;

        .input-group-text {
            background-color: white;
            // height: 40px;
            // margin: 5px 0;
            border: 0 !important;
        }

        .input-group {
            height: 100%
        }
        .bar-input {
            border: 0 !important;
            flex-grow: 1;
            
        }

        .btn-outline-creativesearch {
            font-weight: normal;
            border-radius: 3;
            
        }

        .submit-creativesearch {
            height: 40px;
        }
    }
}

.ssp-cs-filter {
    >div {
        margin-bottom: 5px;
    }

    @media screen and (max-width: 1124px){
        >div {
            &:nth-child(3), &:nth-child(10){
                flex-basis: 100%;
            height: 0;
            }

            &:nth-child(4){
                order: 1;
            }

            &:nth-child(6){
                order: 1;
            }
            &:nth-child(7){
                order: 2;
            }
            &:nth-child(8){
                order: 2;
            }
            &:nth-child(9){
                order: 1;
                margin-right: 50px;
            }

        }
}
     @media screen and (max-width: 856px){
          >div {
                &:nth-child(9){
                order: 3;
                }
            }
        }

    @media screen and (max-width: 1749px) and (min-width: 1125px) {
        >div {
            &:nth-child(6){
                order: 2;
            }

            &:nth-child(7){
                order: 2;
            }

            &:nth-child(8) {
                order: 2;
            }

            &:last-child{
                flex-basis: 100%;
                height: 0;}
        }
    }
}



.filterbar select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add some styling */
  
    display: block;
    width: 100%;
    max-width: 320px;
    height: 100%;
    float: right;
    padding: 0px 24px 0px 12px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    background-color: #eeeeee;
    background-image: none;
    border: 0 !important;
    -ms-word-break: normal;
    word-break: normal;
    
    &::-ms-expand {
      display: none;
    }
  }

  .bardiv {
    position: relative;
    margin-left: auto;

    &:after {
        content: '\e903';
        font-family: 'icomoon' !important;
        color: $steel;
        right: 4px;
        height: 34px;
        padding: 10px 0px 0px 8px;
        position: absolute;
        pointer-events: none;
      }
  }

.break-flex {
    flex-basis: 100%;
    height: 0;
}

.ssp-cs-filters {
    font-weight: bold;
    color: #788599;
    font-size: 14px;
    cursor: normal;
}

.pad-two-perc {
    padding-top: 2%;

    &--none {
        display: none;
    }
}

.filter-pb {
    padding-bottom: 1%;
}

.creative-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // min-height: 100vh; /* set min container height to viewport height */

    #scrollTopBtn {
        display: block; /* Hidden by default */
        position: fixed; /* Fixed/sticky position */
        bottom: 85px; /* Place the button at the bottom of the page */
        right: 10px; /* Place the button 30px from the right */
        z-index: 99; /* Make sure it does not overlap */
        border: none; /* Remove borders */
        outline: none; /* Remove outline */
        background-color: #F46C89; /* Set a background color */
        color: white; /* Text color */
        cursor: pointer; /* Add a mouse pointer on hover */
        padding: 15px; /* Some padding */
        border-radius: 10px; /* Rounded corners */
        font-size: 18px; /* Increase font size */

        i {
            border: solid white;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
          }

        .up {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
          }

          span {
            display: none
        }
      }
      
      #scrollTopBtn:hover {
        background-color: $secondary;
        //background-color: #00d090; /* Add a dark-grey background on hover */
        span {
            border: #c0c0c0 1px dotted;
            padding: 5px 5px 5px 5px;
            display: block;
            z-index: 100;
            background: #bea1a79e no-repeat 100% 5%;
            right: 40px;
            width: 70px;
            position: absolute;
            top: 18px;
            text-decoration: none;
            font-size: 10px;
            color: #322f2f;
        }
      }

}


 
.creative-cards {
    flex: initial;
    border: 1px solid #788599;
    width: 300px;
    height: 100%;
    margin-top: 30px;
    margin-right: 30px;
    cursor: pointer;

    &--pub {
        height: 325px;
    }

    .creative-image-container {
        display: table;
        padding: 15px;
        height: 225px;
        width: 100%;
        text-align: center;
        position: relative;
    }

    .feedback-flag-icon {
        position: absolute;
        top: 0px;
        margin-right: 2px;
        right: 0;
        z-index: 1;
        cursor: pointer;
      }

      .custom-flag {
        -webkit-text-fill-color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;

        &-exists {
            -webkit-text-fill-color: $confiant-red;
            opacity: 0.75;
        }
      }

      

    img {
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      .trick {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        height: 213px;
        width: 100%;
      }

    .no-screenshot {
        color: #8d8888;
    }

    .info-container {
        height: 65px;
    }

    .info-container--pub {
        height: 80px;
    }

    .info-container-item {
        height: 100%;
        background-color: #eeeeee;
    }

    .adomain {
        font-size: 12px;
    }

    .cr-bottom {
        width: 190%;
    }
}
 
.item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2%;

    .dsp-data {
        font-weight: bold;
        font-style: italic;
        margin-left: 1px;
        font-size: 0.800em;    
}

    .issuealert-data {
        color: #ED1941;
        font-weight: bold;
    }

    &--second {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 3%;

        span {
            font-style: italic;
            font-weight: light;
        }

        .issuealert-data {
            color: #ED1941;
            font-weight: bold;
        }

        .crid-container {
            width: 53%;
            word-wrap: break-word;
            white-space: nowrap;
            text-overflow: ellipsis;

            .provider {
                font-weight: bold;
                font-style: normal;
                align-self: flex-end;
            }

            .bottom-row {
                margin-bottom: 3%;
                width: 190%;
            }
        }
    }
}

.num-results {
    color: gray;
    font-weight: bold;
}

.scroll-btn {
    display: flex;
    flex-direction: column;
}

.infinite-loader-container {
    left: 0;
    right: 0;
    z-index: 10001;
    text-align: center;

}

.infinite-loader-message {
    color: #333;
    background: none;
    z-index: 1011;

    img {
        -ms-transform: scale(0.5);
        -webkit-transform: scale(0.5);
        -o-transform: scale(0.5);
        -moz-transform: scale(0.5);
        transform: scale(0.5);
    }
}

.loading-image {
    position: absolute;
    left: 50%;
    margin-left: -100px;
    top: 50%;
}

#min_network_load, #min_impressions {
    -webkit-appearance: none;
    appearance: none;
    height: 40px;
    margin: 13px 0px 0px 0px;
    padding: 0px 28px 0px 12px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #979797;
    -ms-word-break: normal;
    word-break: normal;
    border-radius: 0;
  }

  #creative-search-clear-all {
    position: relative;

  }
  .issueNameToolTip {
      display: block;
  }
  
  .creative-card-flag {
    bottom: 5px !important;
	border-radius: 0px;
	// box-shadow: #acacac 0px 0px 20px 0px;
	background-color: #eeeeee;

	.tooltip-inner {
		background-color: #eeeeee !important;
		// max-width: none !important;
		// max-width: 270px !important;
        span {
            color: #000;
        }
	}

	.arrow {
        height: 0;
        &::before {
            top: 0;
            border-width: 0.4rem 0.4rem 0;
            border-top-color: #eeeeee;
         }

		color: #acacac;
	}
}

.error-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .header {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .sub-header {
        color: $subheader-gray;

    }

    .sub-header span {
        color: $confiant-red;
        font-weight: bold;
    }

    .radio-container {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        width: 80%;

        .text-container {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            width: 70%;

            input[type='text'] {
                flex: 1;
                width: 100%; 
                margin-left: 16px;
            }

            .text-below {
                margin-top: 0px;
                font-size: 12px;
                font-style: italic;
                color: $subheader-gray;
            }
            .text-below-char {
                font-size: 12px;
                margin-top: 0px;
                color: $confiant-red;
              }
        }
    }

    .limit-reached {
        outline: none;
        border: 2px solid $confiant-red;
      }

    label {
        display: flex;
        align-items: center;
        margin-right: 16px;
        width: 150px;
    }

    label span {
        margin-left: 8px;
        font-weight: bold;
    }

    .confirm-dialog-actions {
        display: flex;
        margin-top: 16px;
        width: 100%;
        justify-content: flex-end;
    }

    .btn {
        margin-right: 8px;
    }
}


  