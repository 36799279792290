/**
 * The CSS for the /adtrace page.
 */

 #ad-trace-container {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Helvetica", sans-serif;
}

.ad-trace-title {
    text-align: left;
    margin: 0;
    padding-top: 6px;
    font-weight: bold;
    color: $subheader-gray;
    width: 40%;
    font-size: 0.6rem;
    bottom: -3px;
    white-space: nowrap; 
    position: relative;
}

h2.title {
    margin: 3px 0;
    color: #333;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;

    &.arrow {
        font-size: 1rem;
        padding: 3px;
        cursor: default;
    }
}

.bottom-bordered {
    border-bottom: 1px solid #ddd;
    padding: 5px;
}

.scrollable {
    overflow: auto;
    height: 100%;
}

#dom-chain {
    height: auto;
    max-height: calc(100% - 203px);
}

#dom-chain > div > ul > li > p > a {
    color: #647bad;
    padding: 3px;
    background: #F7F7F7 none repeat scroll 0 0;
    border: 1px solid #c0c9db;
    border-radius: 3px;
    font-size: 11px;
    word-break: break-all;
}

#dom-chain > div > ul:last-child {
    margin-bottom: 2px;
}

section#ad-serving-context table {
    font-size: 13px;
    width: 100%;
    table-layout: fixed;
}

section#ad-serving-context table tr {
    height: 1.75em;
}

section#ad-serving-context table tr td:first-child {
    padding-right: 5px;
    padding-left: 0px;
}

section#ad-serving-context table tr td:nth-child(2) {
    padding-left: 0px !important;
}

section#ad-serving-context table td {
    border-top: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
}

// .adinstance-report {
//     width: 100%;
//     height: 100%;
// }

#ad-trace-container > .table {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 0 !important;
    width: 100%;
}

#ad-trace-container > .table > * {
    flex: 1 100%;
}

#ad-trace-container > .fill-page {
    height: calc(100vh - 60px);
    width: 100vw;
}

#ad-trace-container > .table .top {
    height: 45%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
}

#ad-trace-container > .table .top.full {
    height: 100%;
}

.ace-editor-container {
    position: relative;
    // height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 0 !important;
}
.ace-editor-container > * {
    // height: 100%;
    width: 100%;
}

#ad-trace-container > .table .bottom {
    /*border-top: 3px solid #1a1a1a;*/
    height: calc(55% - 8px);
    display: flex;
    overflow: auto;
    flex-direction: column;
}

.resize-bar {
    height: 8px;
    background-color: #1a1a1a;
    cursor: row-resize;
    width: 100%;
}

#ad-trace-container > .table .panel {
    flex: 1;
    overflow: auto;
    padding: 0px !important;
    height: calc(100vh - 60px);
}

#ad-trace-container > .table .panel > section {
    font-size: 13px;
    padding-left: 18px;
}

#ad-trace-container > .table > .top > .panel,
#ad-trace-container > .table > .bottom > .panel {
    border-right: 3px solid #eaeaea;
}

#ad-trace-container > .table > .top > .panel:last-child,
#ad-trace-container > .table > .bottom > .panel:last-child {
    // border-right: 0;
    border-left : 0;
}

#ad-trace-container > .table > .top > .panel > textarea {
    height: 100%;
}

#ad-trace-container > .table > .top > .panel > .CodeMirror {
    height: 100%;
    min-height: 300px;
    width: 100%;
}

#ad-trace-container > .nav {
    /*width: calc(130% + 18px);*/
    border-bottom: 1px solid #eaeaea;
    height: 49px !important;

}

#ad-trace-container > .nav.flexible {
    display: flex;
    align-items: flex-end;
    border-bottom-color: transparent;
}

#ad-trace-container > .nav > .flex-5 > .nav-link {
    display: inline;
    color: $white;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background-color: $inactive-tab;

    &.active {
        background-color: $chart-blue;
    }
}

#ad-trace-container > .nav > a.float-right {
    float: right;
    padding: 5px 15px;
    text-decoration: none;
    color: #0e0f2e;
    cursor: pointer;
}

#ad-trace-container > .nav > .logo-link {
    display: flex;
    height: 80%;
    flex: 1 1;
}

#ad-trace-container > .nav > .logo-link > img {
    height: 100%;
    margin-left: 5px;
}

#ad-trace-container > .nav > .flex-5 {
    flex: 5;
    margin: 0;
    height: 60%;
    bottom: 5px;
    position: relative;
}

@media screen and (max-width: 768px) {
    #ad-trace-container > .table .top {
        justify-content: flex-end;
    }

    #ad-trace-container > .table .top.full {
        width: 100%;
        height: 100%;
    }

    #ad-trace-container > .table .panel {
        width: 100%;
        height: 250px;
        overflow: auto;
        flex: none;
    }

    #ad-trace-container > .table .panel:last-child {
        width: 100%;
        height: calc(100% - 250px);
    }

    #ad-trace-container > .table > .top > .panel > .ace-editor-container {
        height: 100%;
        width: 100%;
        overflow: auto;
        flex: none;
    }

    .search-code {
        top: 299px;
    }
}

@media all and (min-width: 768px) {
    #ad-trace-container > .table .panel:last-child {
        flex: 5 0;
    }
}

.adtrace-call-chain > ul > li:first-child > p {
    margin-top: -10px;
}

.adtrace-call-chain {
    padding-bottom: 5px;
}

.adtrace-call-chain h2.dot {
    margin: 0;
    margin-bottom: -11px;
    margin-left: 6px;
    margin-top: -11px;
    color: #777;
    font-size: 17px;
}

.adtrace-call-chain ul,
.adtrace-call-chain li {
    list-style: none;
    margin: 0;
    padding: 1px;
}

.adtrace-call-chain ul {
    padding-left: 8px;
}

.adtrace-call-chain li.chain-container {
    border-bottom: 0px;
}

.adtrace-call-chain li.empty {
    font-style: italic;
    color: silver;
    border-color: silver;
}

.adtrace-call-chain li p {
    margin: 0;
    background: transparent;
    position: relative;
    top: 11.2px;
}

.adtrace-call-chain li p > a {
    color: #647bad;
    display: block;
    padding: 3px;
    background: #F7F7F7 none repeat scroll 0 0;
    border: 1px solid #c0c9db;
    border-radius: 3px;
    width: 120px;
    font-size: 12px;
    word-break: break-all;
}

.adtrace-call-chain li ul {
    border-top: 1px solid #777;
    margin-left: -16px;
    padding-left: 24px;
}

.adtrace-call-chain ul li:last-child > ul {
    border-left: 1px solid #f9f9f9;
    margin-left: -17px;
}

.adtrace-call-chain ul li:last-child > ul:after {
    content: open-quote;
    background-color: #f7f7f7;
    width: 1px;
    height: 1px;
    color: transparent;
    position: relative;
    top: 2px;
    left: -26px;
    border-left: 1px solid #f7f7f7;
    display: block;
}

.notice {
    position: relative;
    top: 50%;
    text-align: center;
}

.flex-notice {
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    -webkit-display: -webkit-box;
    -webkit-display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 28px;
}

.search-code {
    position: fixed;
    right: 20px;
    z-index: 99;
    margin-top: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 2px solid #8f938f;
    color: #8f938f;
    background-color: #2c2828;
    cursor: pointer;
    font-size: 12px;

    i {
        margin-top: 3px;
    }
}

.warn-badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    color: #212529;
    background-color: #ffc107;
}

.adtrace-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.adtrace-impression-title {
    font-size: 12px;
    font-weight: bold;
    color: #333;

    &.ast {
        min-width: 80px;
    }

}

.adtrace-impression-content {
    font-weight: 300;
}

.long-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ace_search.right{
    overflow: visible;
    position: fixed;
    top: 49px;
}

.ace_selection {
    background-color: #ca7b75 !important;
    border-color: #ca7b75 !important;
}

.ace_selected-word {
    background-color: #b0b0b0 !important;
    border-color: #b0b0b0 !important;
}

.p-url {
    padding-left: 0.75rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -ms-word-break: break-all;
}

.issue-detect-info {
    
    span {
        text-align: left;
        font-size: smaller;

        &:first-child {
            font-weight: bold;
            font-size: inherit;
        }
    
    }
}
@media screen and (max-width: 992px) {
    #ad-trace-container > .nav > .logo-link {
        top: -15px;
        height: 60%;
        position: relative;
    }
    #ad-trace-container > .nav > .logo-link > .ad-trace-title {
        display: none;
    }
}

@media (max-width: 1040px)  and (min-width: 992px) {
    #ad-trace-container > .nav > .logo-link > img {
        height: 80%;
    }
}

@media (min-width: 992px) and (max-width: 1040px) {
    #ad-trace-container > .nav > .logo-link > .ad-trace-title {
        padding-top: 3%;
        font-size: 0.5rem;
    }
}

#consent-details {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;

    .tracking-entity-table {
        width: 100%;
        table-layout: fixed;
        tbody {
            th, td {
                border: none;
                padding: 0.25em;
            }

            th {
                width: 40%;
                font-weight: inherit;
                color: #788599;
            }

            td {
                width: 60%;
                font-weight: bold;
                color: #150d45
            }
        }
    }

    .column {
        flex: 50%;
        padding: 10px 15px;
        border-right: solid 1px $white-two;
        &:last-child {
            border-right-width: 2px;
        }
    }

    .info {
        font-style: italic;
        font-size: 1em;
        font-family: Open Sans, sans-serif;
        color: #788599;
    }

    .entity {
        font-weight: 600;
        font-size: 20px;
        color: #cf2d4f;
    }
      
    /* On screens that are 992px wide or less, go from two columns to one column */
    @media screen and (max-width: 992px) {
        .column {
        flex: 100%;
        }
        
    }

    /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {
        .row {
        flex-direction: column;
        }
        
        .scrollable-row {
            border: 1px solid #bebebe;
            height: 230px;
        }

    }

    h1.title {
        color: $chart-purple;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;

        small {
            position: relative;
            color:$subheader-gray;
            top: -5px;
        }
    }

    .consent-detail-header {
        text-transform: uppercase;
        padding-left : 0;
        &.collapsible {
            cursor: default;
        }
        &.title { color: $steel; }
        
        &.appendix {
            color: $chart-red;
            text-transform: capitalize;
            margin-top: 2px;
        }
  
    }
  
    .data-row{
      padding: 0 15px;
      &.titles, label.titles {
          font-weight: 600;
          color: $confiant-purple;

          >i {color: #797979}
      }
      &.user-session {
        width: 100%;
         .session {
            //  width: 90%;

             .titles {
                width: 95px;
             }

            .url {
                width: 80%;
            }

            label {
                word-break: break-all;
            }

         }
        }
      
      &.scrollable-row {
        border: 1px solid #bebebe;
        height: calc(100vh - 400px);
        overflow-x: hidden;
        overflow-y: auto;

            ul, li {
                list-style: none;
            }

            ul {
                padding: 0 15px;
                color: #000;
            }

            li {
                padding: 1px 0;
            }

            .value-color {
                &-string {
                    color: $success-green;
                }

                &-number {
                    color: $confiant-red;
                }

                &-boolean {
                    color: $privacy-gold;
                }

                &-undefined {
                    opacity: 0.6;
                }
            }
        
      }

      .consent-string-box {
        background-color: #ECF3F7;
        width: fit-content;
        span {
            word-break: break-all;
        }
    }
    }

    .entity {
        :first-child{
            font-weight: bold;
            font-size: larger;
        }

    }

    .data-row {
        span {
            text-align: left;
        }
    }

    table {
        tbody{
            td {
                border:  solid 1px #bebebe;
            }
        }
    }

    .mismatch-details-table 
    {
        width: 60%;

        .gdpr {
            tr{
                &:first-child {
                    td {
                        color: $confiant-purple;
                        font-weight: 800;

                        &:last-child {
                           color: #000;
                        }
                    }
                }
            }
        }
        

        tbody tr{
            td {
                padding: 0.5rem;

                span {
                    cursor: default;
                }

                &:first-child {
                    color: $confiant-purple;
                    font-weight: 600;
                }

                &:last-child {
                    color: $chart-red;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    background-color: $table-bg-blue;
                }
            }
        }
    }

    .scrollable-table {
        border: 1px solid #bebebe;
    }

    .scrollable-tracking-table {
        table {
            width: 100%;
            table-layout: fixed;
            border: none;
            border-bottom: 1px solid #bebebe;
            border-left: 1px solid #bebebe;
            color: #000;
            border-collapse: collapse;

            td {
                text-align: left;
            }

            th, td {
                display: inline-block;
                border: 1px solid #bebebe;
                border-left: none;
                border-bottom: none;
                
                &:last-child{
                    width:100% !important;
                }
            }

            .cookie 
            {   th, td {
                    &:first-child{
                        width:50% !important;
                        border-right: none;
                    }
                    
                    &:last-child{
                        width:50% !important;
                        border-left: 1px solid #bebebe;
                    }
                }}

            thead {
                border: none;
                min-width: 100%!important;
                th {
                    color: #000;
                    font-size: 12px;
                    font-weight: 600;
                    border-bottom: none;
                }
                tr {
                    border: none;
                    width: 100%!important;
                    display: inline-block;
                    background-color: $table-bg-blue;
                    font-weight: 600;
                    
                }
            }
            tbody {
                border: none;
                width: 100%!important;
                max-height: 232px;
                display: block;
                overflow-y: auto;
                // border: 1px solid #bebebe;

                tr {
                    width: 100% !important;
                    display: inline-block;
                    border: none;

                    td {
                        word-break: break-all;
                    }
                }
            }
        }
    }

    .obj-list {
        :first-child{
            font-size: 1em;
        }

        &.first-obj {
            color: $subheader-gray;
        }
        span {
            cursor: default;
        }
    }

    .consent-string-details-table 
    {
        width: 90%;

        th, td {
            width: 30%;
        }

        thead {
            tr {    
                border-left: solid 1px #bebebe;
            }
            th {
                border-bottom: none;
                border-right: solid 1px #bebebe;
                border-top: solid 1px #bebebe;
                background-color: $table-bg-blue;
                color: #000;
                font-weight: 600;
            }
        }

        tbody tr{
            border: none;
            td {
                padding: 0.5rem;

                &:first-child {
                    color: $confiant-purple;
                    font-weight: 600;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }


  }

  .purposes-body {
    max-height: 232px;
    display: block;
    overflow-y: auto;
  }

  .purposes{
    display: flex;
    &:first-child{
        border-top: none;
    }
    border-top: 1px solid #bebebe;
    
    &.header{
        border-top: none;
        border-bottom: 1px solid #bebebe;
        background-color: #f4f8fa;
        span{
            color: #000;
            font-size: 12px;
            font-weight: 600;
          }
    }

    span {
        padding: 0.75rem;
        word-break: normal;
        &:first-child {
            width: 15%;
            text-align: center;
            border-right: 1px solid #bebebe;
          }
        
      &:last-child {
          width: 85%;
          text-align: left;
      }
  }

}

  .adtrace-table-row-tooltip {
      .tooltip-inner {
          max-width: 280px !important;
      }
  }

  .tooltip-tree-list {
    list-style-type: none;

    li {
        .obj-list {
            .arrow-down {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid #000;
                align-self: center;
            }

            .tooltip-colored {
                color: $confiant-red;
            }
        }
    }
  }

  .mismatch-answer-col {
      &--black {
          color: #000;
      }

      &--red {
          color: $confiant-red;
      }
  }

  .adtrace-header-button {
    border-width: 0;
    appearance: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem !important;
    box-sizing: border-box;
  }