
.download-container-items {
    display: flex;
    flex-direction: column;
    margin-top: 9%;
}

.download-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.submit-pubblocks {
    height: 40px;
    margin: 5px 0px;
}

.date-button-actions-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .date-filter-container {
        margin-right: 10px;
  
        .react-bootstrap-daterangepicker-container {
          padding-left: 0 !important;
        }
  
        .btn {
          background: transparent;
          padding-left: 0;
          font-size: .7rem;
  
          &:focus, &:active, &:hover {
            box-shadow: 0 0 0 red;
            border-color: transparent;
          }
  
          span {
            color: #788599;
          }
  
          .fa-calendar {
            margin-right: 5px;
            color: $black;
          }
  
          .icon-down-arrow {
            font-size: 12px;
            color: $black;
          }
        }
      }
}

.ssp-messaging {
  span {
    color: #A0A4A7;
    padding-bottom: 30px; 
    font-size: 18px;
  }
}