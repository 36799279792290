.btn {
  @include border-radius(0);
  font-weight: 700;
  /*padding: 0.375rem 1.5rem;*/
  &.btn-primary {
    background-color: $secondary;
    border: 2px solid $secondary;
    text-transform: uppercase;
    &.block-allow-single-btn {
      min-width: 60%;
    }
    &:hover,
    &:active,
    &:visited,
    &:focus {
      background-color: darken($secondary, 5%);
      border-color: darken($secondary, 5%);
    }
  }
  &.btn-secondary {
    background-color: transparent;
    border: 2px solid $secondary;
    color: $secondary;
    text-transform: uppercase;
    &.block-allow-single-btn {
      width: 40%;
    }
    &.lg-export-btn {
      width: 125px;
      padding-left: 13px;
      padding-right: 13px;
      margin-left: 8px;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &:hover,
    &:active,
    &:visited,
    &:focus {
      border-color: darken($secondary, 10%);
      color: darken($secondary, 10%);
    }
    &.btn-optional {
      border-color: $steel;
      color: $steel;
      &:hover,
      &:active,
      &:visited,
      &:focus {
        border-color: darken($steel, 10%);
        color: darken($steel, 10%);
      }
    }
    
  }
  &.btn-transparent {
    background: none;
    &:after {
      display: none;
    }
  }
  &.btn-link {
    font-size: 0.875em;
    padding: 0;
    vertical-align: baseline;
    &.btn-text{
      font-size: 14px;
      vertical-align: middle;
    }
  }
}