top-controls-container{
	padding-bottom: 20px;

	&--properties {
		// padding:10px 0 20px;
		padding-bottom: 20px;
	}
}

.properties-dropdown {
	padding-left: 0px;
}

.properties-tabs{
	margin-bottom:45px;

	&.nav-tabs{
		border-color:transparent;
		.nav-link{
			text-transform:uppercase;
			line-height: 1.1;
			color: $primary;
			font-size: 20px;
			padding-left: 0;
			padding-right: 0;
			margin-right:50px;
			color:$primary;
			opacity: 0.6;
			border-color:transparent;
			border-bottom:2px solid transparent;
			&:hover{
				border-color:transparent;
				opacity: 1;
			}
			&.active{
			border-bottom-color:$primary;
			opacity: 1;
		}
		}
	}
}

.tab-description {
	color: #A0A4A7;
	padding-bottom: 30px;
	font-size: 18px;
}

.tab-margin-top{
	margin-top: 45px;
}

.ssp-alert-padding {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

.custom-tabs{
	span{
		font-size:14px;
		color:$primary;
		display:inline-block;
		margin-right:25px;
		text-transform:uppercase;
		font-weight: bold;
		margin-bottom:25px;
		opacity:0.6;
		cursor:pointer;
		&:last-child{
			margin-right:0px;
		}
		&.active{
			border-bottom:2px solid $primary;
			opacity:1;
		}
	}
}

.properties-header-select {
	// margin-left: 10px !important;
	top: 20px !important;
	select {
		font-size: 14px !important;
	}

}

.custom-select-control{
	background:#ffffff;
	width:171px;
	border-color:#979797;
	display:inline-block;
}

@media (min-width:800px){
	.text-lg-right{
		text-align:right;
	}
}

.width-100{
	width:100px;
}
.width-125{
	width:125px;
}

.width-150{
	width:150px;
}

.width-175{
	width:175px;
}

.width-200{
	width:200px;
}

.custom-links {
	display:inline-block;
	line-height:40px;
	margin:0 0 0 30px;

	&--properties-header {
		display:inline-block;
		line-height:40px;
		margin:0 0 0 30px;
		font-size: 16px;
	}


}

.disabled-link {
	cursor: not-allowed;
}

.dark-gray-btm-bdr{
	border-bottom:1px solid #788599;
	&.lightened {
		opacity: 0.4;
	}
}

.non-quality{
	label {
		opacity: 0.4;
	}
}


.light-gray-top-bdr{
	border-top:1px solid #dee2e6;
}

.dark-gray-top-bdr{
	border-top:1px solid #788599;
}

.pb-20{
	padding-bottom:20px;
}
.pt-30{
	padding-top:30px;
}

.pt-15{
	padding-top:15px;
}
.pt-20{
	padding-top:20px;
}
.mb-20{
	margin-bottom:20px;
}
.mt-10{
	margin-top: 10px;
}
.mb-40{
	margin-bottom:40px;
}
.mb-neg-1{
	margin-bottom: -1em;
}

.mb-30{
	margin-bottom:30px;
}
.mt-30{
	margin-top: 30px;
}

.pl-10{
	padding-left:10px;
}

.pl-40{
	padding-left:40px;
}

.section-heading{
	min-height:59px;
}

.page-subtitle-gray{
	font-size:1.250em;
	color: #788599;
	padding-bottom:16px;
	margin-bottom:64px;
	span {
		text-align: left;
	}
	&.alert-page-subtitle{
	    margin-bottom: 10px;
    	margin-top: 20px;
	}
	&.detection{
		margin: 0;
	}
	&.wrapper {
		margin-bottom: 20px;
		padding-top: 10px;
	}
	&.wrappers{
		margin-bottom: 0px !important;
	}
	&.monitoring {
		margin-bottom: 16px !important;
		
		.additional-text {
			font-size: 0.85em;
			font-weight: normal;
			font-style: italic;
			a {
				color: #788599;
			}
		}
	}
}
.footnote{
	border-top:1px solid #e8e8e8;
	margin-top:40px;
	padding-top:24px;

	label{
		color:#211656;
		font-size:0.875em;
		font-weight:bold;
		margin-bottom:0
	}
	p{
		color:#788599;
		font-size:0.875em;
	}
}

.subtitle-3{
	margin-bottom:10px;
	padding-bottom:20px;
}

.code-buttons-wrap{
	button{
		width:45%;
		&.btn-secondary{
			margin-right:15px;
		}
	}
}
.full-size-btns{
	button{
		width: 100%;
	}
}
.lbl-12{
	font-size:0.750em;
	color:#788599;

	&--h {
		padding-top: 10px;
		font-size: 1em;
		color: $primary;
		font-weight: 600
	}

	&--title {
		font-size: 1em;
		font-weight: bold;
		text-transform: uppercase;
		color: #211656;
	}

	&--subtitle {
		font-size: 1em;
		color: $primary;
		font-weight: 600
	}
}
.selection-btn{
	color:#211656 !important;
	margin-bottom:20px;
	&.disable{
		opacity:0.6;
	}
}

.clipboard-alert{
	position:fixed;
	bottom:-16px;
	border: 0;
	width:100%;
	z-index:2001;
	text-align: center;
	padding: 1.1rem 2rem;
	border-radius: 0;
	background-color:#48688c;
	color: #ffffff;
	font-size: 1.250em;
	display:none;
}

.monitoring-orders-container{
	border: 1px solid rgba(0, 0, 0, 0.125);
	min-height: 302px;
	overflow: auto;
}

.modal-mask {
	background-color: #FFFFFF;
	opacity: 0.5;
	z-index: 9999;
}

.modal-mask-alert {
	position: relative;
	z-index: 999999;
	background-color: #f9ed62;

	.icon-alert {
		display: block;
		width: 33px;
		height: 25px;
		position: relative;
		top: 50%;
		margin-top: -12.5px;
	}

	> div {
		text-align: left !important;
		padding-bottom: 15px;
	}
}

.modal-body,
.modal-footer {
	position: relative;
}

#excludedOrders {
	background: $white-three;
	width: 100%;
	padding: 10px;
	border: none;
	margin: 0 10px;
}

.radio-btn-group {
	// border-bottom: 1px solid $white-two;
	padding: 10px 20px 20px 20px;
}

.radio-btn {
	display: inline-block;
	cursor: pointer;
	margin-right: 35px;
	text-decoration: none !important;

	&:hover {
		text-decoration: none;
	}

	.radio-mark {
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 2px solid $confiant-purple;
		border-radius: 10px;
		margin-right: 5px;
		position: relative;
		top: 4px;
	}

	&.selected {
		
		.radio-mark::before {
			display: inline-block;
			width: 10px;
			height: 10px;
			position: relative;
			top: -2px;
			left: 1px;
			background-color: $confiant-purple;
			content: '';
			border-radius: 10px;
		}
	}
}

.list-group-container {
	height: 425px;
}

.confiant-list-grp{
	font-size:0.875em;
	border:1px solid #e8e8e8;
	max-height: calc(100vh - 500px);
    overflow: auto;

	.list-group-item{
		border-left:0;
		border-right:0;
		&:hover, &.active {
			background-color: $sidebar-purple;
			border-color:rgba(0, 0, 0, 0.125);
			color:#ffffff;
		}
		&:hover{
			cursor:pointer;
			opacity: 0.7;
		}
		&:first-child{
			border-top:0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
		&:last-child {
			border-bottom:0;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	&.no-border{
		border:0;
	}

}

.label-bold {
    color: $primary;
    font-weight: 600;
    font-size: 1em;
}

.scaling-grid {
	display: grid;
	width: max-content;

	.text-right {
		justify-self: end;
	}
}

@include media-breakpoint-down(sm) {
	.xs-mt-40 {
		margin-top:40px;
	}
	.xs-mb-20 {
		margin-bottom:20px;
	}
	.xs-mb-40 {
		margin-bottom:40px;
	}
	.codes-container{
		label{
			margin-bottom:17px;
		}
		.code-buttons-wrap{
			text-align:left !important;
			button{
				padding-left:5px;
				padding-right:5px;
				text-align:center;
			}
		}

	}
	.selection-btn.disable{
		display:none;
	}

	.properties-tabs{
		margin-top:20px;
		margin-bottom:25px;
		&.nav-tabs{
			border-color:transparent;
			.nav-link{
				font-size: 20px;
				margin-right:15px;
			}
		}
	}

	.form-group .custom-select-control{
		width:100px;
	}
}

.confirm-dialog {
	background: #FFF;
	border: 3px solid $confiant-purple;
	color: $confiant-purple;
	padding: 20px;
	font-weight: bold;

	.confirm-dialog-actions {
		text-align: right;
		margin-top: 20px;

		&--banned-domains {
			text-align: center;
		}
		button {
			margin-left: 10px;
		}
	}
}

.confirm-dialog--banned-domains {
	background: #FFF;
	border: 3px solid $confiant-purple;
	color: $confiant-purple;
	padding: 20px;
	font-weight: bold;

	.confirm-dialog-content{
		text-align: center;
		.first-message {
			font-size: 1.2em;
		}
		.second-message {
			font-weight: normal;
			font-size: 1em;
		}
	}

	.confirm-dialog-actions {
		text-align: center;
		margin-top: 20px;

		button {
			margin-left: 10px;
		}
	}
}


.properties-row-button {
	  align-self: flex-end;
	  margin-top: 2%;

	  i {
		  color: $secondary;
	  }

	  span {
		  font-weight: 600;
		  font-size: 14px;
		  margin-left: 9px;
	  }
}

.botton-row {
	padding-top: 5%;

	.col-button {
		text-align: right;
	}
}

.codes-container {
	padding-bottom: 10px;
}

.modal-creativewrapper {
	.modal-content {
		width: 800px;
		height: 550px;

		.modal-header {
			background-color: #edebeb;
		}

		.modal-body {
			height: 100%;
			textarea {
				width: 100%;
				height: 100%;
				background-color: #e3e2e2;
			}
		}
	}

}

.modal-onpage {
	.modal-content {
		width: 800px;
		height: 300px;

		.modal-header {
			background-color: #edebeb;
		}

		.modal-body {
			height: 100%;
			textarea {
				width: 100%;
				height: 80%;
				background-color: #e3e2e2;
			}
		}
	}

}

.alert-sub-container {
	padding-right: 0 !important;
}

.wrapper-footer {
	padding-top: 15px;
}

.wrapper-container {
	margin-top: 10px;
	padding-bottom: 15px;
}

@media (min-width: 768px) {
	.properties-top {
		margin-left: 180px !important;
	}
}

@media (min-width: 1700px){
	.ssp-properties-header{
	  width: 89.5%;
	}
  }

.header-background {
	width: 100%;
	background-color: white;
}

.alert-header {
	font-size: 1.5em !important;
	font-weight: 600 !important;
	text-transform: uppercase;
	color: $black !important;
}

.alert-group {
	border-bottom: 1px solid $border-light !important;
	margin-bottom: 10px;

	.alert-group-label {
		font-size: 1.35em;
		color: $black;
	}
}

// SSP Alert Configuration
.alert-element {
	@media (max-width: 767px) {
		flex: 1;
	}

	&.col-form-label {
		font-size: 1.35em;
		color: $black;
	}

	&.alert-link {
		justify-content: center;
		flex-direction: column;
		text-align: center;
		padding-top: 7px;
		padding-bottom: 7px;
		width:100%;


		.alert-link-edit {
			cursor: pointer;
			position: relative;
			top: 2px;
			font-weight: 400;
			color: $confiant-purple;
			text-align: right;

			& > i {
				cursor: pointer;
				color: $confiant-purple;
				padding-left: 5px;
			}
		}

		& > .alert-specs-form {
			width: 100%;
			display: flex;

			& > .alert-specs-input-group {
				text-align: right;
				margin-left: auto;

				& > input {
					width: 60px;
					padding-right:24px;
					text-align:right;

				}

				& > .alert-unit-label {
					margin-left:-24px;
					font-size: 99%;
					font-weight: 400;
				}
			}

			& > .alert-specs-button-group {
				padding-left: 5px;

				& > button {
					text-align: right;

					& > .alert-check-icon {
						font-size: 1.25rem;
						color: $confiant-purple;
						color: #60C69A;
						padding-left: 5px;
						padding-right: 5px;
					}

					& > .alert-cancel-icon {
						font-size: 1.25rem;
						color: red;
						padding-left: 5px;
						padding-right: 5px;
					}
				}
			}
		}
	}

	&.alert-react-switch-container {
		float: right;
		padding-top: 4px;
		padding-left: 4px;

		& > .react-switch {
			position: relative;
			top: 4px;
			float: right;
		}
	}

	&.network-specs-element {
		width: 100%;
	}

	& > .network-specs-form {
		width: 100%;
		display: flex;

		& > .network-specs-inputs {
			flex-grow: 1;


			& > .network-specs-input-group {
				text-align: right;

				& > label {
					padding-right: 5px;
					font-weight: 400;
				}

				& > input {
					width: 60px;
					padding-right:20px;
					text-align:right;
				}

				& > .network-unit-label {
					margin-left:-20px;
					font-size: 99%;
					font-weight: 400;
				}
			}
		}

		& > .network-specs-button {
			padding-left: 5px;

			& > .network-button-link {
				text-align: right;

				& > .network-check-icon {
					font-size: 1.25rem;
					color: $confiant-purple;
					color: #60C69A;
					padding-left: 5px;
					padding-right: 5px;
				}

				& > .network-cancel-icon {
					font-size: 1.25rem;
					color: red;
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
	}
}

.slider-container {
		margin-left: 10%;
		margin-right: 10%;
		height: 100px;
		width: 90%;

		.bottom-label {
			top: 60px !important;
		}

		.slider-label-heavy-ads{
			
			&--start{
					font-size: 1.2em;	
					position: relative;
					margin-left: -25px;
					top: -10px;
			}

			&--end {
					font-size: 1.2em;
					position: relative;
					top: -40px;
					float: right;
			}
		}

		.slider-label-heaviness-score{
			&--start{
					font-size: 1.1em;
					font-style: italic;
					position: relative;
					margin-left: -5%;
					top: -10px;
					color: #797d82;
			}

			&--end {
					font-size: 1.1em;
					font-style: italic;
					position: relative;
					top: -10px;
					float: right;
					margin-right: 5%;
					color: #797d82;
			}
		}

		.rc-slider-rail {
			height: 4px !important;
			background-color: #b4b4b4 !important;
		}

		.rc-slider-handle-1{
			border: solid 2px  $primary !important;
			background-color: #fff !important;
		}

		.rc-slider-handle-2{
			border: solid 2px  $primary !important;
			background-color: $primary !important;
		}

		.rc-slider-track {
			background-color: transparent !important;
		}

		.rc-slider-dot-active {
			border-color: #e9e9e9 !important;
		}

		.rc-slider-mark-text {
			white-space: nowrap;
		}

		.rc-slider-heaviness {
			background-color: #e9e9e9;

			&:before {
				content: "";
				position: absolute;
				height: 100%;
				width: 120%;
				left: -10%;
				top: -35%;
			}
			&:after {
				content: "";
				position: absolute;
				height: 100%;
				width: 120%;
				left: -10%;
				z-index: -1;
			}

			.rc-slider-mark-text {
				transform: translate(-50%, 20%) !important;
				font-style: italic;
				font-size: 12px;

				&:last-child {
					transform: translateX(-50%) !important;

					.heaviness-mark-label {
						display: flex;
						flex-direction: column;
						margin-top: 5%;
					}
				}
			}
		}
}

.slider-blocking{
	.rc-slider-handle-1{
		border: solid 2px  $primary !important;
		background-color: $primary !important;
	}
}

.rc-slider-with-marks{
	width: 90% !important;
}

.detection-dot {
  height: 61%;
  width: 13px;
  background-color: #fff;
	border: 2px solid $primary ;    
  border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
}

.blocking-dot {
  height: 62%;
  width: 13px;
  background-color: $primary;
  border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
}

.detect-block-label {
		font-size: 1.35em;
		line-height: 1.5;
		margin-bottom: 0px;
		font-weight: 500;
}

.navigation-modal, .detection-comp-modal {
	&--gpt-refresh {
		.modal-content {
			border: 5px;
			width: 80%;
			margin-left: 10%;		}
	
		.modal-body {
			padding: 0px;
		}
		
	}

	.modal-content {
		border: 5px;
		width: 100%
	}

	.modal-body {
		padding: 0px;
	}

	.confirm-dialog {
		font-weight: 600;
		font-size: 1.2em;
	}
}

.heaviness-popover {
	z-index: 100000001 !important;
	max-width: 400px !important;
	position: absolute;
	top: 50px;
	left: 0px;
	transform: translate3d(870px, 354px, 0px);
	will-change: transform;
}

.heaviness-label-logo {
	height: 30px;
	width: 30px;
}

.heaviness-modal {
	.heaviness-container {
		width: 90%;
		margin: auto;
		font-size: 18px;

		.rc-slider-heaviness {
			.rc-slider-handle {
				position: relative;
				width:  1em;
				height: 1em;
				border-top-right-radius: 30%;
				background-color: #20C997;
				text-align: left;
				border: 0;
				border-radius: 0;
				transform: rotate(-60deg) skewX(-30deg) scale(1,.866) translateX(-50%) translateY(-75%) !important;
				filter: drop-shadow(0 0.1rem 0.5rem rgba(0, 0, 0, 0.3));

				&:before {
					transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
				}

				&:after {
					transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
				}

				&:before, &:after {
					content: '';
					position: absolute;
					background-color: inherit;
					width:  1em;
					height: 1em;
					border-top-right-radius: 30%;
				}
			}
		}
	}

	.heaviness-factors-title {
		color: #150d45;
		font-weight: 800;
		font-size: 20px;
		margin: 0;
	}

	.heaviness-score-breakdown {
		position: relative;
		z-index: 1;
		padding: 1em;
		background-color: #F2F2F2;
		margin: 1em 0;

		> h3 {
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 800;
			text-align: center;
		}

		> p {
			font-size: 14px;
			margin-bottom: 1em;
			font-weight: 200;
			text-align: center;
		}

		.heaviness-score-breakdown-label {
			&--start{
				font-size: 0.6em;
				font-weight: 600;
				position: relative;
				left: -9%;
				top: -10px;
				color: #797d82;
			}

			&--end {
					font-size: 0.6em;
					font-weight: 600;
					position: relative;
					right: -1%;
					float: right;
					color: #797d82;
			}
		}
	}

	.heaviness-factors {
		margin-left: 1.5em;
		padding: 1em;

		> li {
			font-size: 16px;
		}
	}

	.heaviness-score-breakdown-text {
		color: #797d82;
	}
}

.banned-controls{
	background:#ffffff;
	width: 171px;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	height: calc(1.5em + 0.75rem + 2px);
}

.edit-view-list {
	display: flex;
	align-items: center;

	.active {
		color:#20072e !important;
	}

	.inactive {
		color: #898888 !important;
	}

}

.bundle-opacity {
	opacity: 0.4 !important;
}

.attributes-table {
  thead {
    color: $primary;
		
    th {
      border: none;
      border-bottom: 1px solid $steel;
      &:first-child {
        min-width: 125px;
				padding-left: 30px;
				width: 20%;
      }
      &:last-child {
        max-width: 160px;
        padding-right: 25px;
      }
      &.sortable {
        cursor: pointer;
      }
    }
  }
  tbody {
    font-size: 14px;
		color: $primary;
		border: 1px solid #e8e8e8;
    tr {
      td {
        border: none;
        &:first-child {
          padding-left: 30px;
					opacity: 1;
					color: $primary; 
					font-weight: 600;
        }
        &:last-child {
					padding-right: 30px;
					color: #5A5863;
					font-size: 12px;
        }
      }
      &:nth-child(odd) {
        background-color: $pale-grey;
      }
    }
  }
}

#AddPropertyModalBodyBlocking {
	padding-bottom: 0 !important;
}

.monitoring-nodfp {
	margin-top: 10%;
	span {
		font-weight: 600;
		font-size: 1.25em;
		color: $primary;
	}

	i {
		color: $warning-yellow;
	}
}

.radio-btn-spans {
	font-weight: 600;
}

.alert-no-order{
	border-top: 2px solid #c5c5c5;
	color: $steel;
	font-size: 1.25em;
	p {
		margin-top: 1rem;
	}
	a {
		color: darken(#c5c5c5, 10%);
	}
}

.add-property-tooltip {
	&--amazon {
		color: $warning-yellow;
	}

	&--native {
		color: $warm-grey;
	}
}

.integrations-tooltip {
	padding: 20px 10px 20px 10px;
	float: none;
	border-radius: 0px;
	box-shadow: #888888 0px 0px 20px 0px;
	background-color: #FFFFFF;
	left: 10px !important;
	// top: 10px !important;
	max-width: 280px;

	.tooltip-inner {
		background-color: #FFFFFF !important;
		max-width: none !important;
		max-width: 270px !important;
	}

	.arrow {
		left: -6px;
	}
}

.other-property-ext {
	color: $subheader-gray;
	font-weight: normal;
}

.info-text {
    margin-left: 5%;
    color: #797d82;
    text-transform: none; 
    font-weight: normal;
}

.api-key-text {
	background-color: #e9ecef;;
	width: 60%;
}

.api-settings {
	width: 50%;
	label {
		font-size: 1.25em;
		font-weight: 600;
		color: $primary;
	}
}