.popover1 {
  
  line-height: normal;
  color: $white;
  pointer-events: none;

  .popover-body {
    background: $confiant-purple;
    padding: 10px 15px;
    max-width: 325px;
    color: $white;
    font-size: 1.142857em;
  }

  &.bs-popover-right {
    margin-left: 1rem;
    .arrow {
      border-color: transparent;
      border-style: solid;
      border-width: 8px;
      border-right-color: $confiant-purple;
      position: absolute;
      left: calc((0.8rem + 1px) * -1);
    }
  }
}