.notification-dropdown {
  a {
    cursor: pointer;
  }

  //left: auto !important;
  width: 400px;
  //right:0;
  //top: 47px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border: 0;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 1.1428em;
  max-height: 400px;
  overflow: auto;

  > div {
    padding-left: 15px;
    padding-right: 15px;
    &:last-child{ 
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .btn-link.load-more{
    font-size: 0.875em;
    padding: 0;
    vertical-align: baseline;
    cursor: pointer;
    appearance: none;

  }
}
.notification-row {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $steel;
  font-size: 1.1428em;
  opacity: 1;
  .tick{
    color: $secondary;
    margin-right: 0.5em;
  }
  &.read { 
    .memo-info-container{
      opacity: 0.5;
      .tick{
        color: $steel;
        display: inline;
      }
    }
  }

  .memo-info{
    font-size: 16px;
    line-height: 1.25;
    color: $primary;
    margin-bottom: 0;
    padding: 0;
    a{
      color: $primary;
    }
  }
  .memo-date{
    font-size: 13px;
    line-height: 1.54;
    margin-top: 5px;
    color: #999999;
  }
} 

.notification-header{
  background: $primary;
  color: $white;
  line-height: 1.25em;
  font-size: 15px;
  padding: 10px 0;
  margin-top: -8px;
  margin-bottom: 15px;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  .archive-all{
    cursor: pointer;
    margin-right: 15px;
  }

  @include media-breakpoint-down(sm) { 
      width: 95vw;
      max-height: 80vh;
      margin-top: 15px;
      white-space: normal;
    
  
  @include media-breakpoint-only(sm){
    margin-top: 15vh;
  }

  }
}

